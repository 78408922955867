@import '../../../App.css';
@import '../../../_variables.scss';

.RatingSwiper {
  background-color: var(--white-F6F6F6);
  padding: 0;
  padding: rem(220) 0 rem(60) 0;

  &__header {
    margin-bottom: rem(60);
    display: flex;
    justify-content: center;
    font-family: var(--font-unbounded);
    font-size: rem(74);
    font-weight: var(--fw-300);
    line-height: rem(80);
    text-align: left;
    color: var(--black-1D2E43);

    h2 {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    span {
      display: block;

      &:last-child {
        padding-left: rem(407);
      }
    }
  }

  &__body {
    padding: 0 rem(113) 0 rem(113);

    & > * {
      overflow: visible;
    }
  }

  &__wrapper {
    padding: rem(30) 0;
    align-items: stretch;
  }

  &__slide {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(54);
    height: auto;
  }

  &__date {
    font-size: rem(18);
    display: inline-block;
    color: var(--gray-959DA6);
    margin-bottom: rem(64);
  }

  &__title {
    font-size: rem(24);
    font-weight: var(--fw-300);
    line-height: rem(32);
    font-family: var(--font-unbounded);

    &:not(:last-child) {
      margin-bottom: rem(32);
    }
  }

  &__row {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: rem(56);
    background-color: #f8f8f8;
    color: var(--black-3C3C3C);
    box-shadow: 0 0 rem(20) 0 #00000008;
    border-radius: rem(16);
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: rem(54);
  }

  &__picture {
    margin-top: auto;

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 16px;
      object-fit: cover;
      aspect-ratio: 2.2;
    }
  }

  &__button {
    align-self: flex-end;
    position: relative;
    min-height: rem(32);
    border-radius: rem(34);
    padding: rem(8) rem(36) rem(8) rem(16);
    background-color: var(--light-light-gray-EBECEE);
    margin-top: rem(8);
    transition: all 0.3s ease;

    &:before {
      position: absolute;
      right: rem(10);
      transform: translateY(-50%);
      top: 50%;
      width: rem(16);
      height: rem(16);
      position: absolute;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgNkw4IDExTDEzIDYiIHN0cm9rZT0iIzFEMkU0MyIvPgo8L3N2Zz4K');
      background-size: contain;
      content: '';
      transition: all 1s ease;
    }

    &:hover {
      background-color: var(--light-gray-C0C5CB);
    }

    &.visible {
      &:before {
        transform: translateY(-50%) rotate(180deg);
        transition: all 1s ease;
      }
    }
  }

  // Адаптация под desktop
  @media all and (max-width: $XXL) {
    padding: rem(140) 0 rem(16) 0;
    margin-bottom: rem(24);

    &__header {
      margin-bottom: rem(42);
      font-size: rem(65);
      line-height: rem(75.5);
      padding: 0 rem(155) 0 rem(162);

      h2 {
        gap: 16px;
      }

      span {
        display: block;

        &:last-child {
          padding-left: rem(100);
        }
      }
    }

    &__body {
      padding: 0 rem(63) 0 rem(63);
      position: relative;

      &::before,
      &::after {
        width: rem(112);
        position: absolute;
        content: '';
        background-color: var(--white-F6F6F6);
        height: 100%;
        z-index: 10;
        content: '';
      }

      &::before {
        top: 0;
        left: -4rem;
      }

      &::after {
        top: 0;
        right: -4rem;
      }
    }

    &__slide,
    &__rows {
      gap: rem(30);
    }

    &__slide {
      &:nth-child(even) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  @media all and (max-width: $XL) {
    &__header {
      padding: 0 rem(32);
      gap: 16px;
    }

    &__row {
      padding: rem(36);
    }
  }
  // Адаптация под tablet
  @media all and (max-width: $L) {
    padding: 0;

    span {
      display: block;
    }

    &__header {
      font-size: rem(40);
      line-height: rem(48);
      padding: rem(120) 15% rem(54) 15%;
      margin: 0;

      h2 {
        align-self: flex-start;
        gap: 0;
      }

      span:last-child {
        padding-left: 0;
      }
    }

    &__body {
      padding: 0;

      &::before,
      &::after {
        content: none;
      }
    }

    &__slide,
    &__rows {
      gap: rem(24);
    }

    &__body,
    &__adaptive {
      display: flex;
      flex-direction: column;
    }

    &__slide {
      display: flex;
      flex-direction: column;
      gap: rem(24);
      padding: 0 15% 0 15%;

      &:nth-child(even) {
        flex-direction: column-reverse;
      }

      &.hide {
        max-height: 0;
        opacity: 0;
        padding: 0 15% 0 15%;
        transition: max-height 1s ease, opacity 1s ease, padding 1s ease;
      }

      &.visible {
        max-height: rem(2000);
        opacity: 1;
        padding: 0 15% rem(24) 15%;
        transition: max-height 4s ease, opacity 1s ease-in-out 0.4s, padding 1s ease;
      }

      &:not(:first-child) {
        padding: rem(24) 15% 0 15%;

        &.visible {
          padding-bottom: rem(24);
        }
      }
    }

    &__row {
      padding: rem(24);
    }

    &__title {
      font-size: rem(20);
      line-height: rem(28);
    }

    &__date {
      margin-bottom: rem(48);
      font-size: rem(16);
    }

    &__button {
      margin: rem(32) 15% 0 15%;
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    padding: 0;
    display: flex;
    flex-direction: column;

    &__header {
      font-size: rem(32);
      line-height: rem(40);
      padding: rem(80) rem(16) rem(32) rem(16);
    }

    &__slide {
      padding: 0 rem(16) 0 rem(16);
      gap: rem(8);

      &:not(:first-child) {
        padding: 24px rem(16) 0 rem(16);
      }
    }

    &__rows {
      gap: rem(8);
    }

    &__row {
      padding: rem(16);
    }

    &__title {
      font-size: rem(20);
      line-height: rem(28);
    }

    &__button {
      margin: rem(20) rem(16) 0 0;
      font-size: 14px;
    }
  }
}
