.form_container {
  width: 44rem;
  padding: 1.62rem 1rem 1.75rem 2rem;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 72vh;
  }

  &__heading_block {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }

  &__heading_block_input {
    border: 1px solid var(--blue-75BBFD);
    border-radius: 6px;
    padding: 1rem 3.5rem 1rem 1rem;
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
      color: var(--gray-959DA6);
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__heading_block_input_length {
    position: absolute;
    right: 7px;
    bottom: 25%;
    color: var(--gray-959DA6);
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__heading_block_label {
    color: var(--light-gray-C0C5CB);
    font-family: var(--font-raleway);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  } 
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: var(--font-raleway);
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--gray-959DA6);
  outline: 0;
  font-size: 1rem;
  color: var(--black-1D2E43);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  position: relative;
  // caret-color: var(--blue-75BBFD);
}

.form__field:focus {
  border-color: var(--blue-75BBFD);
}

.form__field:focus::caret {
  color: var(--your-caret-color);
}

.form__field::placeholder {
  color: transparent;
}

.form__field:not(:placeholder-shown)~.form__label {
  font-size: 0.8rem;
  cursor: text;
  top: 5px;
  font-weight: var(--fw-400);
  color: var(--light-gray-C0C5CB);
}

.form__label {
  position: absolute;
  top: 20px;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--gray-959DA6);
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .form_container {
    &__form {
      height: 60vh;
    }
  }
}

@media screen and (max-width: 1024px) {
  .form_container {
    width: 33rem;

    &__form {
      height: 70vh;
    }

    &__button_block {
      position: fixed;
      display: flex;
      bottom: 8%;
      padding-left: 0px;
      gap: 7px;
      z-index: 9;
    }
  }
}