@import './../_variables.scss';

.FooterForm {
  $s: &;
  font-family: var(--font-unbounded);
  font-weight: var(--fw-300);
  opacity: 0;
  transform: scale(0.9);
  animation: fadeIn 0.3s forwards;

  &__container {
    margin: 5rem 0 1rem 0;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__top {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    display: flex;
    justify-content: space-between;
    gap: 10%;
    margin-bottom: rem(124);
  }

  &__bottom {
    margin-right: 2rem;
    margin-left: 2rem;
    color: var(--dark-gray-566272);
  }

  &__left {
    width: 40%;
    margin-top: rem(90);

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__right {
    margin-top: rem(146);
    width: 49%;
  }

  &__subtitle {
    font-size: rem(48);
    line-height: 133%;
    margin-bottom: rem(90);
    color: var(--black-1D2E43);
  }

  &__border {
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--gray-959DA6);
    padding-top: rem(24);

    &:hover {
      cursor: text;
    }
  }

  &__input {
    position: relative;
    margin-bottom: rem(96);
    font-size: rem(20);
    line-height: 140%;

    input,
    textarea {
      display: block;
      min-height: rem(53);
      width: 100%;
      height: 100%;
      padding-bottom: rem(24);
      color: var(--black-1D2E43);
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      background-color: transparent;
      border-bottom: 1px solid var(--gray-959DA6);
      position: relative;
      resize: none;
      transition: 0.3s;

      &:not(:placeholder-shown) {
        &+#{$s}__placeholder {
          top: rem(-44);
          transition: 0.3s;
          font-size: rem(16);
        }
      }

      &:hover {
        border-bottom: 1px solid var(--salmon-FF7F6A);
        color: var(--salmon-FF7F6A);
        transition: 0.3s;

        &+#{$s}__placeholder {
          color: var(--salmon-FF7F6A);
        }
      }

      &:focus {
        border-bottom: 1px solid var(--salmon-FF7F6A);
        transition: 0.3s;
        caret-color: var(--salmon-FF7F6A);

        &+#{$s}__placeholder {
          top: rem(-44);
          transition: 0.3s;
          font-size: rem(16);
          color: var(--gray-959DA6);
        }

        &:hover {
          color: var(--black-1D2E43);
        }
      }
    }

    textarea {
      min-height: rem(165);
      resize: none;
      overflow-y: auto;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
      padding-right: rem(10);

      &:hover {
        border-bottom: none;

        &+#{$s}__placeholder+#{$s}__border {
          border-bottom: 1px solid var(--salmon-FF7F6A);
        }
      }

      &:focus {
        border-bottom: none;

        &+#{$s}__placeholder+#{$s}__border {
          border-bottom: 1px solid var(--salmon-FF7F6A);
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        /* Ширина полосы прокрутки */
        margin: rem(12);
      }

      &::-webkit-scrollbar-track {
        background: var(--light-light-gray-EBECEE);
        /* Цвет фона полосы прокрутки */
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--light-gray-C0C5CB);
        /* Цвет бегунка */
        border-radius: 4px;

        &:hover {
          background-color: var(--gray-959DA6);
        }
      }
    }

    &_error {

      input,
      textarea {
        color: var(--crimson-E00747) !important;
        border-bottom: 1px solid var(--crimson-E00747);

        &+#{$s}__placeholder {
          color: var(--crimson-E00747);
        }

        &+span+#{$s}__textError {
          height: auto;
          opacity: 1;
          transition: 0.5s;
          transform: translateY(0);
        }

        &:focus,
        &:hover {
          transition: 0.5s;
          border-bottom: 1px solid var(--crimson-E00747) !important;
          outline: none;
          caret-color: var(--crimson-E00747);
          color: var(--crimson-E00747) !important;

          &+#{$s}__placeholder {
            color: var(--crimson-E00747);
            transition: 0.5s;
          }
        }

        &:hover {
          border-bottom: 1px solid var(--crimson-E00747);
          color: var(--crimson-E00747);
          transition: 0.3s;
          caret-color: var(--crimson-E00747);

          &+#{$s}__placeholder {
            color: var(--crimson-E00747);
          }
        }

        &:not(:placeholder-shown) {
          border-bottom: 1px solid var(--crimson-E00747);
        }
      }
    }

    &_tel {
      margin-bottom: rem(64);
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    color: var(--gray-959DA6);
    z-index: -1;
    transition: 0.3s;
  }

  &__textError {
    position: absolute;
    display: block;
    height: 0;
    opacity: 0;
    font-family: var(--font-raleway);
    font-size: rem(14);
    line-height: 142%;
    color: var(--crimson-E00747);
    margin-top: rem(8);
    transition: 0.5s;
    transform: translateY(rem(-20));
  }

  &__conclusion {
    width: min-content;
  }

  &__agreement {
    font-family: var(--font-raleway);
    font-size: rem(12);
    line-height: 133%;
    margin-bottom: rem(24);
    color: var(--gray-959DA6);

    a {
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__submit {
    font-size: rem(40);
    font-weight: var(--fw-300);
    line-height: 120%;
    color: var(--black-1D2E43);
    position: relative;
    display: flex;
    gap: rem(31);
    transition: 0.3s;

    &::after {
      box-sizing: border-box;
      content: '';
      display: block;
      width: rem(48);
      height: rem(48);
      background-color: var(--black-1D2E43);
      border-radius: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjgxMDYxIDAuMDQxMDE1NlYzLjQ0MDAyTDEzLjcxODkgOC45NTU1NEwwLjI4MDAyOSAxNC4zNDg5VjE3Ljk1OTZMMTMuNzIgMTIuNzMyOVY4Ljk1NjAyTDEzLjcyMTEgOC45NTY0NFY1LjI2NzY4TDAuMjgxMDYxIDAuMDQxMDE1NloiIGZpbGw9IiNGNkY2RjYiLz4KPC9zdmc+Cg==');
      transition: 0.3s;
    }

    &:hover {
      color: var(--blue-75BBFD);

      &::after {
        transition: 0.3s;
        background-color: var(--blue-75BBFD);
      }
    }
  }

  .LoadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(8px);   
    z-index: 9999
  }

  @media screen and (max-width: $XXL) {
    &__left {
      margin-top: rem(36);
    }

    &__right {
      margin-top: rem(69);
    }

    &__subtitle {
      font-size: rem(40);
      margin-bottom: rem(80);
    }

    &__top {
      gap: rem(36);
    }
  }

  @media screen and (max-width: $L) {
    &__container {
      margin: 3rem 0 1rem 0;
    }

    &__top {
      margin-bottom: rem(112);
      margin-right: 3rem;
      margin-left: 3rem;
    }

    &__left {
      margin-top: rem(32);
      width: 43%;
    }

    &__right {
      margin-top: rem(48);
      width: 48%;
    }

    &__subtitle {
      font-size: rem(32);
      margin-bottom: rem(100);
    }

    &__input,
    &__placeholder {
      font-size: rem(18);
      font-family: var(--font-raleway);
    }

    &__input {
      margin-bottom: rem(64);

      textarea {
        font-size: rem(18);
        min-height: rem(77);
      }

      &_tel {
        margin-bottom: rem(84);
      }
    }

    &__agreement {
      margin-bottom: rem(16);
    }

    &__submit {
      font-size: rem(32);
      gap: rem(24);

      &::after {
        width: rem(40);
        height: rem(40);
        background-size: 30%;
      }
    }
  }

  @media screen and (max-width: $S) {
    &__top {
      flex-direction: column;
      margin-bottom: rem(90);
      gap: 0;
    }

    &__bottom,
    &__top {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    &__left {
      margin-top: rem(32);
      width: 100%;
      max-width: rem(300);
    }

    &__right {
      margin-top: rem(24);
      width: 100%;
      font-size: rem(20);
    }

    &__subtitle {
      margin-bottom: rem(80);
      font-size: rem(20);
    }

    &__border {
      padding-top: rem(16);
    }

    &__input {
      margin-bottom: rem(60);
      font-size: rem(18);

      input {
        padding-bottom: rem(16);
      }

      input,
      textarea {
        min-height: rem(26);
        color: var(--black-1D2E43);

        &:not(:placeholder-shown) {
          &+#{$s}__placeholder {
            top: rem(-36);
            font-size: rem(12);
          }
        }

        &:focus {
          &+#{$s}__placeholder {
            top: rem(-36);
            font-size: rem(12);
          }
        }
      }

      textarea {
        min-height: rem(78);
      }

      &_tel {
        margin-bottom: rem(56);
      }
    }

    &__submit {
      font-size: rem(24);
      gap: rem(16);

      &::after {
        width: rem(32);
        height: rem(32);
      }
    }     
  }

  @media screen and (max-width: $XS) {

    &__input,
    &__placeholder {
      font-size: rem(17);
    }
   }
}
