.slick-next {
  height: 50px;
  width: 40px;
  max-width: 100%;
  right: 30px;
}

.slick-prev {
  height: 50px;
  width: 40px;
  max-width: 100%;
  left: 30px;
  z-index: 10;
}

.slick-dots {
  bottom: -35px;
}
