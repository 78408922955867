@import '../../App.css';

.map {
  display: flex;
  width: auto;
  height: 64.5vh;
  padding: 0px 0.1vw;
}

.popup {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  max-width: 21rem;
  min-width: 20rem;
  height: fit-content;
  border-radius: 0.5rem;
  margin-top: 10%;
  object-fit: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--white-F6F6F6);
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.popupContent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  text-align: left;
  padding-left: 1rem;
}

.popupImage {
  display: block;
  width: 18rem;
  height: 12rem;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  object-fit: center;
}

.popupTitle {
  display: block;
  color: var(--black-3C3C3C);
  font-family: var(--font-raleway);
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--fw-700);
  line-height: 1.5rem;
  margin-top: 1rem;
  font-variant-numeric: lining-nums;
  max-width: 17rem;
}

/* .popupDescription {
  color: var(--black-3C3C3C);
  font-family: var(--font-raleway);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: var(--fw-700);
  line-height: 0.5rem;
} */

.popupCustomButton {
  display: flex;
  width: fit-content;
  padding: 8px 24px;
  align-items: center;
  justify-content: start;
  border-radius: 2.125rem;
  border: 1px solid var(--black-1D2E43);
  background-color: transparent;
  white-space: nowrap;
  font-family: var(--font-raleway);
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 1.5rem;
  color: var(--black-1D2E43);
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.popupCustomButton:hover {
  background-color: var(--dark-gray-566272);
  color: var(--white-F6F6F6);
}

.popupCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 1;
  width: 1.00006rem;
  height: 1rem;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .map {
    width: 100%;
    height: 40vh;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .map {
    width: 100%;
    height: 70vh;
    padding: 0;
  }
  .popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    max-width: 15rem;
    min-width: 15rem;
    height: fit-content;
    border-radius: 0.5rem;
    margin-top: 10%;
    object-fit: center;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    background-color: var(--white-F6F6F6);
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .popupTitle {
    display: block;
    color: var(--black-3C3C3C);
    font-family: var(--font-raleway);
    font-size: 1rem;
    font-style: normal;
    font-weight: var(--fw-700);
    line-height: 1.5rem;
    margin-top: 1rem;
    font-variant-numeric: lining-nums;
    max-width: 10rem;
  }
}
