@import '../App.css';
@import '../_variables.scss';

.Navbar {
  display: block;

  &.hidden {
    display: none;
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    border-bottom: 1px solid var(--black-1D2E43);
  }

  &__logo {
    background: rgb(246 246 246 / 90%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: var(--font-MyCustomFont);
    font-size: 24px;
    padding-left: 1.87vw;
    width: 100%;
    color: var(--black-1D2E43);
    cursor: pointer;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // cursor: none !important;
  }

  &__title__hr {
    color: #1d2e43;
    font-family: var(--font-unbounded);
    font-size: 1rem;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: normal;
  }

  &__list {
    display: flex;
    height: 100%;
  }

  &__item {
    font-size: 16px;
    font-family: var(--font-raleway);
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 1.25vw;
    background: rgb(246 246 246 / 90%);
    color: var(--black-1D2E43);
    display: flex;
    align-items: center;
    padding-left: 1.53vw;
    padding-right: 1.53vw;
    border-left: 1px solid var(--black-1D2E43);
    white-space: nowrap;
    transition: background 0.3s ease, backdrop-filter 0.3s ease;
    // cursor: none !important;

    &:hover {
      background: rgb(246 246 246 / 60%);
      backdrop-filter: blur(3px);
    }

    &:active {
      background: rgba(212, 237, 251, 0.6);
    }

    &_open {
      background: rgba(212, 237, 251, 0.6);
      backdrop-filter: blur(3px);
      transition: background 0.3s ease, backdrop-filter 0.3s ease;
    }
  }

  &__phone {
    display: none;
  }


 /* Стили для меню на экранах шириной меньше или равной 1024px */
  @media screen and (max-width: 1025px) {
    &__phone {
      display: block;
    }

     .navbar__santaHat,
     .navbar__santaHat_hr {
      display: none;
    }
  }
}