@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import '../App.css';

.public_container_main {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  height: 100%;
  min-height: 80vh;
}

.public_image_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.bg_video {
  width: 100%;
  height: 100%;
  min-height: 52vh;
  object-fit: cover;
  object-position: center;
}

.text_container_publics {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--black-1D2E43);
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-300);
  top: 50%;
  left: 9vw;
}
.text1_publics {
  display: flex;
  padding-right: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  max-width: 50rem;
}
.text2_publics {
  display: flex;
  font-size: 3rem;
  line-height: 4rem;
  padding-top: 0.25rem;
}

.my_swiper3_button_group_article {
  display: none;
}

.menu_button_group_articles_wrapper_main {
  display: flex;
  flex-direction: row;
}

.menu_button_group_articles_wrapper {
  display: flex;
  width: 100%;
}

.menu_button_group_articles {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 5rem 0rem 1.88rem;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  width: 100%;
}

.menu__button__allArticles,
.menu__button__allArticles_active {
  display: flex;
  width: auto;
  padding: 8px 24px;
  align-items: center;
  justify-content: center;
  border-radius: 2.125rem;
  border: 1px solid var(--black-1D2E43);
  background-color: transparent;
  white-space: nowrap;
  color: var(--black-1D2E43);
  cursor: pointer;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.menu__button__allArticles:hover {
  background-color: var(--dark-gray-566272);
  border: 1px solid var(--dark-gray-566272);
  color: var(--white-F6F6F6);
  transition: background-color 0.4s ease, border 0.1s ease, color 0.4s ease;
}

.menu__button__allArticles_active {
  background-color: var(--black-1D2E43);
  color: var(--white-F6F6F6);
}

.menu_button_item__allArticles {
  font-family: var(--font-raleway);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 1.25rem;
}

.section_article {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  padding-bottom: 3.94rem;
  background-color: var(--white-F6F6F6);
  padding-top: 2.5rem;
}

.section_article__block {
  display: flex;
  flex-wrap: wrap;
  gap: 1.88rem;
  padding-left: 1.88rem;
}

.section_article__block_cart {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 1.88rem);
  max-height: 30rem;
}

.cart_article {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cart_article__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}

.cart_article__img {
  max-width: 100%;
  max-height: 21rem;
  min-height: 21rem;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
}

.article_content__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.cart_article_title {
  display: flex;
  width: 100%;
  font-family: var(--font-raleway);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: var(--fw-700);
  line-height: 1.25rem;
  color: var(--black-4E4E4E);
  padding-top: 1rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: anywhere;
  white-space: pre-line;
}

.cart_article__address {
  display: flex;
  color: var(--gray-959DA6);
  font-family: var(--font-raleway);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: normal;
  padding-top: 0.5rem;
}

.cart_article__address__svg {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
}

@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .section_article__block_cart {
    display: flex;
    flex-direction: column;
    width: calc(25% - 1.88rem);
    height: 29rem;
    margin-bottom: 3.94rem;
  }
  .cart_article__img {
    max-width: 100%;
    max-height: 26rem;
    min-height: 26rem;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center;
  }
}

/* Стили для планшетной версии сайта блока Publics начинаются тут */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bg_video {
    width: 100%;
    height: 100%;
    min-height: 40vh;
    object-fit: cover;
    object-position: center;
  }

  .text_container_publics {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-style: normal;
    font-weight: var(--fw-300);
    top: 50%;
    left: 9vw;
  }
  .text1_publics {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text2_publics {
    display: flex;
    font-size: 3rem;
    line-height: 4rem;
    padding-top: 0.25rem;
  }

  .menu_button_group_articles_wrapper_main {
    display: flex;
    flex-direction: row;
  }

  .menu_button_group_articles_wrapper {
    display: flex;
    width: 100%;
  }

  .menu_button_group_articles {
    padding: 2rem 10rem 2.5rem 3.44rem;
    grid-column-gap: 6px;
  }

  .menu__button__allArticles,
  .menu__button__allArticles_active {
    transition: background-color 0.4s ease, color 0.4s ease;
  }

  .menu__button__allArticles:hover {
    background-color: transparent;
    color: var(--black-1D2E43);
    transition: none;
  }

  .menu__button__allArticles.active {
    background-color: var(--black-1D2E43);
    color: var(--white-F6F6F6);
  }

  .section_article {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
    background-color: var(--white-F6F6F6);
    padding-bottom: 3.06rem;
  }

  .section_article__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.88rem;
    padding-left: 3.44rem;
    padding-right: 3.44rem;
  }

  .section_article__block_cart {
    max-height: auto;
    width: calc(50% - 1.88rem);
    margin-bottom: 1rem;
  }

  .cart_article {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
  }

  .cart_article__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cart_article__img {
    min-height: 21rem;
    max-height: 21rem;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center;
  }

  .article_content__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
  }

  .cart_article__address {
    padding-top: 0.5rem;
  }
}

/* Стили для мобильной версии сайта блока Publics начинаются тут */
@media screen and (max-width: 767px) {
  .bg_video {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    object-fit: cover;
    object-position: center;
  }

  .public_container_main {
    width: 100%;
    height: 100%;
  }

  .text_container_publics {
    top: 50%;
    left: 1.25rem;
    right: 1.25rem;
  }
  .text1_publics {
    display: flex;
    font-family: var(--font-raleway);
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: var(--fw-500);
    font-variant-numeric: lining-nums proportional-nums;
  }
  .text2_publics {
    display: flex;
    font-size: 2rem;
    font-style: normal;
    line-height: 2.5rem;
    padding-top: 0.31rem;
    font-weight: var(--fw-300);
    font-family: var(--font-unbounded);
  }

  .menu_button_group_articles_wrapper_main {
    display: none;
  }
  .menu_button_group_articles1 {
    padding-left: 0rem;
  }
  .my_swiper3_button_group_article {
    display: flex;
    padding-top: 1.5rem;
    padding-left: 1rem;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
  }

  .menu__button__allArticles,
  .menu__button__allArticles_active {
    transition: background-color 0.4s ease, color 0.4s ease;
  }

  .menu__button__allArticles:hover {
    background-color: transparent;
    color: var(--black-1D2E43);
    transition: none;
  }

  .menu__button__allArticles.active {
    background-color: var(--black-1D2E43);
    color: var(--white-F6F6F6);
  }

  .section_article {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-left: -1rem;
    padding-right: 1rem;
    background-color: var(--white-F6F6F6);
  }

  .section_article__block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding-left: 1rem;
    padding-bottom: 2.5rem;
  }

  .section_article__block_cart {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 0rem;
    overflow: none;
  }

  .cart_article__container {
    width: 100%;
    height: 100%;
  }

  .cart_article__img {
    width: 100%;
    height: 100%;
    max-height: 21rem;
    min-height: 21rem;
    border-radius: 1rem;
  }

  .article_content__wrapper {
    display: block;
  }

  .cart_article_title {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.31rem;
    padding-top: 1rem;
  }

  .cart_article__content {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }

  .cart_article__address {
    display: flex;
    color: var(--gray-959DA6);
    font-size: 0.625rem;
    padding-top: 0.5rem;
  }
}
