.preview_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .loading {
    font-size: 14px;
    color: var(--gray-C0C5CB);
    text-align: center;
  }

  .preview_photos_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;   
    padding-bottom: 1rem;

    &__block {
      position: relative;
      flex-basis: 100%;
      padding-top: 1rem;
    }

    &__img {
      width: 100%;
      height: 7rem;
      border-radius: 0.39rem;
      object-fit: cover;
      /* Обрезать изображение, сохраняя его пропорции */
      object-position: center;
      /* Центрировать изображение в контейнере */
    }

    @keyframes wave {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-5px);
      }

      100% {
        transform: translateY(0);
      }
    }

    &__loading {
      font-family: var(--font-raleway);
      color: var(--black-1D2E43);
      animation: wave 1s infinite;
    }

    &__remove_icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-F6F6F6);
      position: absolute;
      top: 35%;
      left: 45%;
      cursor: pointer;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.3);
      }

      span {
        position: absolute;
        top: 52%;
        left: 50%;
        width: 2px;
        height: 23px;
        transform: translate(-50%, -50%);

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 3px;
          height: 20px;
          background-color: var(--blue-75BBFD);
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}