@import '../../../App.css';
@import '../../../_variables.scss';

.VacanciesInvite {
  background: linear-gradient(
    261.46deg,
    rgba(255, 203, 193, 0.09) -4.67%,
    rgba(117, 187, 253, 0.09) 44.63%,
    rgba(188, 188, 188, 0.09) 103.07%
  );
  font-family: var(--font-unbounded);
  position: relative;
  overflow: hidden;

  &__star {
    position: absolute;
    z-index: -1;
    background-size: cover;

    &_1 {
      width: rem(290);
      height: rem(290);
      top: 13%;
      left: 74%;
    }

    &_2 {
      width: rem(290);
      height: rem(290);
      left: 5%;
      bottom: 21%;
    }

    &_3 {
      width: rem(135);
      height: rem(135);
      top: 11%;
      left: 56%;
    }

    &_4 {
      width: rem(128);
      height: rem(128);
      left: 30%;
      bottom: 14%;
    }
  }

  &__container {
    width: 64%;
    padding: rem(212) 0 rem(165) 0;
    margin: 0 auto;
  }

  br {
    display: none;
  }

  h2 {
    width: 60%;
    font-size: rem(65);
    font-weight: var(--fw-300);
    line-height: rem(75.5);
    margin: 0 auto rem(94) 0;
    text-align: left;
    color: var(--black-3C3C3C);
  }

  &__content {
    width: 48%;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__desc {
    font-size: rem(40);
    margin-bottom: rem(40);
    color: var(--black-3C3C3C);
    font-weight: var(--fw-300);

    span {
      color: var(--blue-75BBFD);
    }
  }

  &__photos {
    display: flex;
    margin-bottom: rem(40);
    $n: rem(-34);

    img,
    span {
      border-radius: 100%;
      width: rem(80);
      height: rem(80);
      // border: rem(3.68) solid #eaf1f6;
    }

    img {
      &:nth-child(2) {
        transform: translateX($n);
      }

      &:nth-child(3) {
        transform: translateX(calc($n * 2));
      }

      &:nth-child(4) {
        transform: translateX(calc($n * 3));
      }
    }

    span {
      transform: translateX(calc($n * 4));
      display: inline-block;
      background-color: var(--white-F6F6F6);
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNyAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTUuOTQ3MyIgd2lkdGg9IjMuNjgiIGhlaWdodD0iMzYuOCIgZmlsbD0iIzFEMkU0MyIvPgo8cmVjdCB4PSIzNi44MDA4IiB5PSIxNS45NDUzIiB3aWR0aD0iMy42OCIgaGVpZ2h0PSIzNi44IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCAzNi44MDA4IDE1Ljk0NTMpIiBmaWxsPSIjMUQyRTQzIi8+Cjwvc3ZnPgo=');
    }
  }

  @media all and (max-width: $XXL) {
    &__container {
      width: 100%;
      padding: rem(212) rem(62) rem(100) rem(62);
      margin: 0 auto;
    }

    h2 {
      width: 65%;
      margin: 0 auto rem(50) 0;
    }

    &__content {
      width: 45%;
    }

    &__desc {
      font-size: rem(32);
      line-height: rem(40);
      margin-bottom: rem(40);
    }
  }

  @media all and (max-width: $M) {
    br {
      display: block;
    }

    &__star {
      &_1 {
        width: rem(290);
        height: rem(290);
        top: rem(26);
        right: 0;
      }

      &_2 {
        width: rem(290);
        height: rem(290);
        top: rem(509);
        left: rem(594);
      }

      &_3 {
        width: rem(135);
        height: rem(135);
        top: rem(187);
        left: 0;
      }

      &_4 {
        width: rem(128);
        height: rem(128);
        top: rem(286);
        left: rem(593);
      }
    }

    &__container {
      width: 66%;
      padding: rem(100) 0 rem(100) 0;
    }

    h2 {
      width: 100%;
      font-size: rem(40);
      margin-bottom: rem(88);
      line-height: rem(48);
    }

    &__content {
      margin-left: 0;
      width: 100%;
    }

    &__photos {
      margin-bottom: rem(48);
    }

    &__desc {
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(32);
    }
  }

  @media all and (max-width: $S) {
    &__container {
      width: 100%;
      padding: rem(90) rem(16) rem(90) rem(16);
    }

    h2 {
      width: 100%;
      font-size: rem(32);
      line-height: rem(40);
      margin-bottom: rem(90);
    }

    &__desc {
      font-size: rem(20);
      line-height: 1.4;
    }

    &__star {
      &_1 {
        width: rem(143);
        height: rem(143);
        top: rem(15);
        right: rem(15);
        left: auto;
      }

      &_2 {
        width: rem(135);
        height: rem(135);
        top: rem(545);
        left: rem(225);
      }

      &_3 {
        width: rem(63);
        height: rem(63);
        top: rem(648);
        left: rem(147);
      }

      &_4 {
        width: rem(63);
        height: rem(63);
        top: rem(204);
        left: rem(250);
      }
    }

    &__photos {
      $n: rem(-28);
      margin-bottom: rem(32);

      img {
        &:nth-child(2) {
          transform: translateX($n);
        }

        &:nth-child(3) {
          transform: translateX(calc($n * 2));
        }

        &:nth-child(4) {
          transform: translateX(calc($n * 3));
        }
      }

      span {
        transform: translateX(calc($n * 4));
      }
    }
  }
}
