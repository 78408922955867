@import '../../../App.css';

.form__group {
  position: relative;
  padding: 15px 0 0;
  font-size: 1rem;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}