@import '../../../App.css';
@import '../../../_variables.scss';

.GalleryTabs {
  margin: rem(220) rem(113);

  &__title {
    font-family: var(--font-unbounded);
    font-size: rem(40);
    font-weight: var(--fw-300);
    line-height: rem(48);
    margin-bottom: rem(48);

    span {
      color: var(--blue-75BBFD);
    }
  }

  &__noEvents {
    display: flex;
    font-family: var(--font-unbounded);
    font-weight: var(--fw-300);
    color: var(--light-gray-C0C5CB);
    font-size: 1.75rem;
    line-height: 1.75rem;
    padding-top: 3rem;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    margin-bottom: rem(16);
    padding-bottom: rem(16);
    overflow: visible;
  }

  &__button {
    width: auto;
    flex-shrink: 1;
    padding: rem(8) rem(24);
    padding: 8px 24px 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: rem(40);
    border-radius: rem(34);
    color: var(--black-1D2E43);
    font-size: rem(16);
    font-weight: var(--fw-400);
    line-height: rem(24);
    background-color: var(--light-light-gray-EBECEE);
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      background-color: var(--light-gray-C0C5CB);
      // cursor: none;
    }

    &.active {
      background-color: var(--blue-75BBFD);
      color: var(--white-F6F6F6);
    }
  }

  &__content {
    height: 75vh;
    max-height: rem(1080);
    overflow: hidden;
    position: relative;

    * {
      height: 100%;
    }

    img,
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content:hover &__cursor {
    width: 94px;
    height: 94px;
  }

  &__half {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 15;
    background-color: transparent;
    cursor: default;

    &_left {
      left: 0;
      cursor: url('../icons/arrow-right.svg') 8 8, auto;
    }

    &_right {
      right: 0;
      cursor: url('../icons/arrow-left.svg') 4 8, auto;
    }

    @media (pointer: coarse) {
      &_left {
        cursor: default;
      }

      &_right {
        cursor: default;
      }
    }
  }

  &__cursor {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #75bbfd;
    pointer-events: none;
    z-index: 10000;
    transition: background-color 0.3s ease, width 0.3s, height 0.3s;

    @media (pointer: coarse) {
      display: none;
    }
  }

  // Адаптация под меньший размер desktop
  @media all and (max-width: $XXL) {
    margin: rem(150) 0;

    &__buttons,
    &__title {
      padding: 0 rem(63);
    }

    &__buttons {
      padding-bottom: rem(20);
      margin-bottom: rem(20);
    }
  }

  // Адаптация под tablet
  @media all and (max-width: $M) {
    margin: rem(120) 0;

    &__noEvents {
      font-size: 1.75rem;
    }

    &__content {
      max-height: rem(570);
    }

    &__cursor {
      display: none;
    }

    &__half {
      width: rem(32);
      height: rem(32);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBvVfdUdtAEN49CXhgMmMjbIY3uQNTAVBBcAdOBUAFQAU4HdABoQKcCqIOrDcmCMfKMHkg1t1m90AeY0Q4SYbvRT93t99q9+7bFYIjJpNJY6rhgG93EaGLQCERNGSMn1MCjBEgAgVDD+Cq2WymLnbRgTjMDBwyYT8ndDKMeOEpOGNH4v/Og9eJG1qbU/6yQ6gDxEEraB6/OgzF5KE2dM1fHMISwCmKPYX7RdFQBeTdZZILxJbYvL297S6O4QJ5uGzyZ2QFkXgWgfckF+SRkP31woG7u/HgPcnnneBTdZI/2xTYo6ZpBB8I38OOpMJGQM95VBkIEQHErtNFW+SqJB9E1IcaIKSjVrCxA2DO3BdRX7j9qdYH+PI0uiI12nzZ2tr8JsYyQ4dAzmtZ6OCzUujtQgVIuMlk+0/kvLHoml92y9gwAHs+AZValJPr6cP+9vZ2nGsHVDlBRF1VduECeS3VZGEKJfnOFY6Joj/3v3eEPEkme0sQroZfZrbOHnqdTidNkmQP0JLXhkTAqXGwk/3VvlxbrdZQIZ1CfaSKBSR2na0QT34m41O5D4LgrK4THMFYIWBUZpE4kYwn57kTZOgYqgIpUob0dygLoqPk7teliE+7HQwQTA9KpDKHAjVEq2CPhcj5NMzA+u8r7ElRsc2G8i+5uoWuy7kgNZXtXrmBhCpg5Xuq72G73Y7K1AIWwAvhtuX45uYm9FfWKpdj6XRQ4cAYLmqOcpxNHzqiJ7OWLBmPz7msHcFHAIk75cBu3pkDjz8e9KNMDqtApLy9udHJn2d1WPIhGl+mqahCLhzz7541ApITMFnvPZywNtm25ZhD4Y+JbEpvZe16WemYr6CLY4WtkEy0eeLNAnXBNvIKWjj81vrHaKyesGT3wR0p95kXOvv79TViZwdyjEajxvr6pwMuBvx7jt2nRiZXz1SKGpNGylPD+zS9krLtYvcf5EuMbKVX570AAAAASUVORK5CYII=');
      top: calc(50% - rem(16));

      &_left {
        left: rem(24);
      }

      &_right {
        transform: rotate(180deg);
        right: rem(24);
      }
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    &__buttons,
    &__title {
      padding: 0 rem(16);
      margin-bottom: rem(32);
    }

    &__buttons {
      margin-bottom: rem(24);
      padding-bottom: rem(16);
    }

    &__noEvents {
      display: flex;
      align-content: center;
      justify-content: center;
      font-size: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__content {
      max-height: rem(450);
    }

    &__half {
      &_left {
        left: rem(16);
      }

      &_right {
        right: rem(16);
      }
    }
  }

  @media all and (max-width: $XS) {
    &__content {
      max-height: rem(250);
    }
  }
}
