@import '../App.css';

.login_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-family: 'Raleway';
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #dee6ed, #bcdbf9, #f3e2e1);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

.border_line {
  /* Добавляем градиент на бордерах и закругляем углы */
  box-shadow: -2px 0 0 #fff, /* Белый бордер слева */ 2px 0 0 #f6f7f7, /* Серый бордер справа */ 0 -2px 0 #f6f7f7,
    /* Серый бордер сверху */ 0 2px 0 #f6f7f7, /* Серый бордер снизу */ 0 0 16px rgba(0, 0, 0, 0.1);
  /* Тень для создания градиента */
  border-radius: 16px;
  border-radius: 16px;
}

.login_block {
  position: relative;
  display: flex;
  width: 526px;
  height: 432px;
  flex-direction: column;
  align-items: baseline;
  padding: 42px;
  box-shadow: 20px 19px 70px 2px rgba(84, 86, 87, 0.25);
  overflow: hidden;
  border-radius: 16px;

  /* Добавляем градиент на бордерах и закругляем углы */
  // border: 2px solid transparent;
  // border-image: linear-gradient(to right, #adf2f7, #f6f7f7) 1;

  &__title {
    font-family: var(--font-MyCustomFont);
    font-size: 2.4rem;
    color: var(--black-1D2E43);
    padding-bottom: 24px;
  }

  &__subtitle {
    color: var(--black-1D2E43);
    font-family: var(--font-raleway);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 16px;
  }
}

.login_form {
  width: 100%;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: var(--font-raleway);
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--gray-959DA6);
  outline: 0;
  font-size: 1.3rem;
  color: var(--black-1D2E43);
  padding: 7px 0;
  background-color: rgba(0, 0, 0, 0);
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
    font-weight: var(--fw-400);
    color: var(--dark-gray-566272);
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--black-3C3C3C);
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.7rem;
    color: var(--gray-959DA6);
    font-weight: 700;
  }

  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--blue-75BBFD), var(--blue-75BBFD)) 1 / 0 0 2px 0 stretch;
  border-image-slice: 2;
  background-color: rgba(0, 0, 0, 0);
}

/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.form_button {
  color: var(--white-F6F6F6);
  background-color: var(--blue-75BBFD);
  border: none;
  padding: 1rem 2.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
  margin-top: 50px;
  outline: none;

  &:hover {
    background-color: var(--dark-blue-389FFF);
  }

  &:focus {
    background-color: var(--dark-blue-389FFF);
    outline: 1px solid var(--white-F6F6F6);
    /* Добавьте эту строку */
    /* Дополнительные стили, если необходимы */
  }
}

.disabled_button {
  transition: all 0.3s ease-in-out;
  opacity: 0.3;
  pointer-events: none;
}

.welcome_block {
  position: absolute;
  bottom: -100%;
  left: 25%;
}

.error_bnt_block {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.error_div {
  color: var(--crimson-E00747);
  font-family: var(--font-raleway);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding-top: 5px;
}

.error_text {
  color: var(--crimson-E00747);
  border-bottom: 2px solid var(--crimson-E00747);
}
