.company-slider-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.company-slider-slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(83%);
  max-height: 64rem;
}

.navigation_slider {
  position: absolute;
  bottom: 3%;
  right: 2%;
}

.button_slider {
  height: 8rem;
  border: none;
  color: rgba(234, 236, 237, 0.4);
  font-size: 1vw;
  cursor: pointer;
  margin-right: 1.5vw;
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-300);
  line-height: 1.5rem;
  background-color: transparent;
  transition: font-size 0.5s ease-in-out;
}

.active {
  font-size: 2.5vw;
}

.button_slider_wrapper {
  position: relative;
}

.underline {
  position: absolute;
  bottom: 1.25rem;
  height: 0.375rem;
  background-color: #f6f6f6;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.gray-underline {
  position: absolute;
  bottom: 1.25rem;
  height: 0.375rem;
  background-color: rgba(234, 236, 237, 0.4);
  border-radius: 5px;
  width: 88%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .company-slider-slide img {
    height: 64vh;
  }
  .navigation_slider {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .company-slider-slide img {
    min-height: 45vh;
  }
  .navigation_slider {
    display: none;
  }
}
