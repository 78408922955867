@import '../../App.css';

.slider_item {
  width: 29rem;
  height: 24rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: grab;
}

.slider_item_content {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.4s;
}

.slider_image_wrapper {
  height: 100%;
  width: 94%;
  overflow: hidden;
  border-radius: 0px 0px 1rem 1rem;
  position: relative;
}

.slider_image {
  max-height: 100%;
  width: 100%;
  height: 20.37rem;
  transition: 0.2s;
  border-radius: 16px;
  object-fit: cover;
  /* object-fit: contain; */
  object-position: center;
}

.container__selectCompetencies {
  display: flex;
  background: var(--white-F6F6F6);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
}

.countryCity_monthYear {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 0.5rem;
  width: 98%;
}

.container__countryCity {
  color: var(--black-4E4E4E);
  font-family: var(--font-raleway);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: normal;
}

.container__monthYear {
  color: var(--unnamed, #a7a7a7);
  text-align: right;
  font-family: var(--font-raleway);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: normal;
}

.slider_subject {
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-700);
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--black-4E4E4E);
  overflow-wrap: break-word;

  @media screen and (max-width: 520px) {
    & {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.slider_text {
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: var(--gray-959DA6);

  @media screen and (max-width: 520px) {
    & {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

/* .icon_image {
    position: absolute;
    bottom: 6%;
    right: 6%;
} */
