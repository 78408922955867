@import '../../App.css';

@media screen and (max-width: 767px) {
  .swiper_container_wrapper {
    display: flex;
  }

  .swiper_container_articles {
    width: 100%;
    /* border-radius: 1rem; */
  }

  .swiper_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    position: relative;
  }

  .swiper_container_item_articles {
    height: 100%;
    padding-left: 0rem;
    margin-right: 0rem;
  }

  /* .swiper_pagination_container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 3.5rem;
  }

  .swiper_pagination_bullet_container {
    width: 20px;
    height: 4px;
    border: 2px solid var(--light-gray-C0C5CB);
    background-color: var(--light-light-gray-EBECEE);
    margin: 0 5px;
    cursor: pointer;
  }

  .swiper_pagination_bullet_container.active {
    width: 4rem;
    border: 2px solid var(--salmon-FF7F6A);
  } */
}
