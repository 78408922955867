@import '../App.css';

.footer {
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 1.7vh 2.7vw 4vh 2.7vw;
    background-color: var(--black-1D2E43);
  }

  &__mail {
    position: relative;
    z-index: 3;
    padding-bottom: 40px;

    a {
      color: var(--white-F6F6F6);
      font-weight: var(--fw-400);
      font-family: var(--font-raleway);
      font-size: 48px;
      line-height: 48px;
      cursor: pointer;

      &:hover {
        color: var(--salmon-light-FFBFB5);
        transition: color 0.4s ease;
      }

      &:active {
        transform: scale(0.95);
        transition: transform 0.2s ease;
      }

      &:visited {
        color: var(--color-link-active);
      }
    }
  }

  &__office {
    color: var(--white-F6F6F6);
    /* padding-top: 40px; */
    padding-bottom: 14px;
    font-weight: var(--fw-700);
    font-size: 18px;
    line-height: 24px;
    font-family: var(--font-raleway);
  }

  &__contacts {
    position: relative;
    z-index: 3;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
  }

  &__tel,
  &__address {
    color: var(--white-F6F6F6);
    font-family: var(--font-raleway);
    font-size: 14px;
    padding-top: 4px;
    cursor: default;
  }

  &__tel,
  &__address {
    cursor: pointer;
  }

  &__tel:hover {
    color: var(--salmon-light-FFBFB5);
  }

  &__address:hover {
    color: var(--salmon-light-FFBFB5);
  }

  &__block_right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
  }

  &__lang {
    color: var(--white-F6F6F6);

    &:hover {
      color: var(--salmon-light-FFBFB5);
      transition: color 0.4s ease;
    }
  }

  &__socials {
    padding-top: 69px;

    span {
      position: relative;
      z-index: 3;
      color: var(--white-F6F6F6);
      letter-spacing: 0.1em;
      font-size: 14px;
      font-weight: var(--fw-900);
      line-height: 14px;
    }

    a:hover span {
      color: var(--salmon-light-FFBFB5);
      transition: color 0.4s ease;
    }
  }

&__documents {
    font-family: var(--font-raleway);
    display: flex;
    padding-top: 46px;
 
    &_link {
      position: relative;
      z-index: 3;
      color: var(--light-gray-C0C5CB);
      font-size: 14px;
      font-weight: var(--fw-400);
      line-height: 14px;
      padding-left: 24px;
      padding-right: 20px;
      text-decoration: none;
      transition: color 0.4s ease;

      &:hover {
        color: var(--salmon-light-FFBFB5);      
      }
    }
  }


  &__copyright {
    position: relative;
    z-index: 3;
    color: var(--light-gray-C0C5CB);
    font-size: 14px;
    font-weight: var(--fw-400);
    line-height: 14px;
    padding-left: 24px;
  }

  /* Стили для планшетной версии сайта блока Footer начинаются тут */
  @media screen and (min-width: 700px) and (max-width: 1024px) {
    &__container {
      min-width: 0px;
    }

    &__mail:hover,
    &__tel:hover span,
    &__lang:hover,
    &__socials a:hover {
      color: var(--white-F6F6F6);
      transition: none;
    }

    &__documents a:hover {
      color: var(--light-gray-C0C5CB);
      transition: none;
    }
  }

  /* Стили для мобильной версии сайта блока Footer начинаются тут */
  @media screen and (max-width: 700px) {
    &__container {
      min-width: 0px;
      flex-direction: column;
      padding: 2.4vh 1.7vh 4vh 1.7vh;
      /* margin-top: 5vh; */
    }

    &__mail a {
      font-weight: var(--fw-400);
      font-size: 32px;
      line-height: 32px;
    }

    &__lang {
      display: none;
    }

    &__office {
      padding-bottom: 14px;
    }

    &__address {
      font-size: 12px;
    }

    &__block_right {
      align-items: normal;
    }

    &__documents {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      gap: 2vh;
      justify-content: flex-end;
      padding-top: 24px;

      a {
        padding: 0;
      }
    }

    &__copyright {
      padding: 0;
    }
  }
}
