@import '../../App.css';

.form_right_wrapper {
  display: flex;
  width: 100%;
  max-width: 50vw;
  height: fit-content;
  border-radius: 1rem;
  background: linear-gradient(
    221deg,
    rgba(255, 203, 193, 0.09) 0%,
    rgba(117, 187, 253, 0.09) 45.75%,
    rgba(188, 188, 188, 0.09) 100%
  );
  box-shadow: 0px 4px 37px 0px rgba(44, 59, 68, 0.05);
  backdrop-filter: blur(14.5px);
  border-image: linear-gradient(180deg, #f6f6f6 21.88%, rgba(246, 246, 246, 0) 100%);
  border-image-slice: 1;
}

.border_line_sub {
  box-shadow: -1px 0 0 #f6f6f6, 1px 0 0 #f6f6f6, 0 -1px 0 #f6f6f6, 0 1px 0 #f6f6f6, 0 0 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border-radius: 16px;
}

.form_right_main {
  padding: 2.8vw;
}

.title1 {
  display: flex;
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-300);
  font-size: 20px;
  line-height: 28px;
  color: var(--black-1D2E43);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.form_form_wrapper1 {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.label {
  position: relative;
}

.email_input_form {
  border: none;
  outline: none;
  background-color: transparent;
  padding-top: 2.7rem;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: normal;
  max-width: 28vw;
  position: relative;
}

.line1 {
  bottom: 0;
  left: 0;
  max-width: 28vw;
  height: 0.069vh;
  margin-top: 0.55vh;
  background-color: var(--gray-959DA6);
}

.error_line1 {
  position: absolute;
  bottom: -20px;
  left: 0;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 12px;
  line-height: 16px;
  color: var(--crimson-E00747);
}

.placeholder {
  position: absolute;
  top: 90%;
  left: 0;
  color: var(--gray-959DA6);
  font-size: 16px;
  transform: translateY(-100%);
  transform-origin: left top;
  transition: all 0.3s ease-out;
  padding-bottom: 10px;
  width: 100%;
  white-space: nowrap;
}

.email_input_form:not(:placeholder-shown) + .placeholder,
.email_input_form:empty + .placeholder,
.focused .placeholder {
  transform: translateY(-180%) scale(0.75);
  -webkit-transform: translateY(-180%) scale(0.75);
  font-size: 16px;
  color: var(--gray-959DA6);
}

.email_input_form:focus + .line1 {
  background-color: #ff7f6a;
}

.hidden {
  display: none;
}

.button_hover11 {
  margin-top: 2.77vh;
  width: 145px;
  height: 56px;
  border: 1px solid var(--black-1D2E43);
  border-radius: 66px;
  background-color: transparent !important;
  transition: background-color 0.4s ease, color 0.4s ease, border 0.4s ease;
}

.button_hover11:hover {
  background: linear-gradient(133deg, rgba(123, 191, 255, 0.55) 0%, rgba(240, 248, 255, 0.55) 100%);
  color: var(--black-1D2E43);
  border: 0px solid transparent;
}

.button_hover11:hover:not(:hover) {
  transition: background-color 0.4s ease, color 0.4s ease, border 0.4s ease;
}

.button_name {
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 24px;
  color: var(--black-1D2E43);
}

.form_right_wrapper:focus-within .button_hover11 {
  background-color: var(--light-light-gray-EBECEE);
  border: 1px solid var(--gray-959DA6);
}
.form_right_wrapper:focus-within .button_name {
  color: var(--gray-959DA6);
}

.form_right_wrapper:has(.email_input_form:not(:placeholder-shown)) .button_hover11 {
  border: 1px solid var(--black-1D2E43);
}

.form_right_wrapper:has(.email_input_form:not(:placeholder-shown)) .button_name {
  color: var(--black-1D2E43);
}

.form_right_wrapper:has(.email_input_form:not(:placeholder-shown)) .disabled {
  color: var(--black-1D2E43);
}

/* .button_hover11:disabled {
  background-color: var(--light-light-gray-EBECEE);
  pointer-events: none;
} */

/* .button_hover11:disabled:hover {
  background-color: var(--light-light-gray-EBECEE);
  cursor: default;
 } */

/* стили только для планшетной версии  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .icon_partner {
    display: none;
  }

  .form_right_wrapper {
    max-width: 100vw;
  }

  .form_right_main {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 100%;
  }

  .form_form_wrapper1 {
    display: flex;
    flex-direction: column;
    padding-bottom: 0rem;
  }

  .line1 {
    width: 20vw;
  }

  .email_input_form {
    width: 65%;
  }
  .button_hover11:hover {
    background: transparent !important;
    color: var(--black-1D2E43);
    border: 1px solid var(--black-1D2E43);
  }
}
