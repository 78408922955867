@import '../App.css';

$offset: 187;
$duration: 2.5s;
$radius: 1rem;
$small-spinner-size: 82px; // Размер для маленьких экранов
$medium-spinner-size: 120px; // Размер для планшетов
$small-inner-circle-size: 50px; // Внутренний круг для маленьких экранов
$medium-inner-circle-size: 75px; // Внутренний круг для планшетов
$small-div-svg-size: 82px; // Основной SVG контейнер для маленьких экранов
$medium-div-svg-size: 120px; // Основной SVG контейнер для планшетов

.spinner {
  animation: rotator $duration linear infinite;
  width: 165px;
  height: 165px;

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    width: $medium-spinner-size;
    height: $medium-spinner-size;
  }

  @media screen and (max-width: 768px) {
    width: $small-spinner-size;
    height: $small-spinner-size;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  border-radius: $radius;
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #feb0a4;
  }

  25% {
    stroke: #ffa597;
  }

  50% {
    stroke: #fec6bd;
  }

  75% {
    stroke: #ff9f8f;
  }

  100% {
    stroke: #feb0a4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset * 0.25;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.div_svg {
  position: relative;
  display: inline-block;
  height: 163px;
  width: 163px;
  margin: 0;
  border-radius: 50%;
  background: #e1eefc;
  overflow: hidden;

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    height: $medium-div-svg-size;
    width: $medium-div-svg-size;
  }

  @media screen and (max-width: 768px) {
    height: $small-div-svg-size;
    width: $small-div-svg-size;
  }
}

.div_svg:after {
  content: '';
  position: absolute;
  top: 33px;
  left: 33px;
  display: block;
  height: 100px;
  width: 100px;
  background: #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    top: 22px;
    left: 22px;
    height: $medium-inner-circle-size;
    width: $medium-inner-circle-size;
  }

  @media screen and (max-width: 768px) {
    top: 16px;
    left: 16px;
    height: $small-inner-circle-size;
    width: $small-inner-circle-size;
  }
}
