@import '../App.css';

.contentLoaderContainer {
  font-family: var(--font-MyCustomFont);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(
      261deg,
      rgba(255, 203, 193, 0.09) -4.67%,
      rgba(117, 187, 253, 0.09) 44.63%,
      rgba(188, 188, 188, 0.09) 103.07%
    ),
    #f6f6f6;
  overflow: hidden;
}

.conentLoaderLogo {
  position: relative;
  display: flex;
  max-width: 556px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.itemLoaderLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contentLoaderSubtitle {
  font-size: 1.35569rem;
  line-height: 3.38919rem;
  padding-bottom: 47px;
  text-align: center;
}

.contentLoaderTitle,
.contentLoaderSubtitle {
  color: var(--white-F6F6F6);
}

.contentLoaderBlock {
  transform-origin: top;
  z-index: 5;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percent {
  font-family: var(--font-unbounded);
  color: var(--white-F6F6F6);
  font-size: 20px;
  font-weight: var(--fw-300);
  line-height: 28px;
  text-align: center;
  margin-bottom: 15px;
  z-index: 5;
}

.textBlink {
  animation: animate 0.7s ease-in-out 0.1s;
}

/* ---- Большие кружки ---- */

.contentLoaderCircles {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipseFirst {
  width: 1440px;
  height: 1440px;
  animation: ellipse 6s ease-in-out infinite;
  animation-delay: 100ms;
}
.ellipseSecond {
  width: 1188px;
  height: 1188px;
  animation: ellipse 6s ease-in-out infinite;
  animation-delay: 200ms;
}
.ellipseThird {
  width: 936px;
  height: 936px;
  animation: ellipse 6s ease-in-out infinite;
  animation-delay: 300ms;
}
.ellipseFour {
  width: 689px;
  height: 685px;
  animation: ellipse 6s ease-in-out infinite;
  animation-delay: 400ms;
}

.ellipseAll {
  will-change: transform, opacity;
  position: absolute;
  border-radius: 50%;
  transform-origin: center;
  background: linear-gradient(
    261deg,
    rgba(255, 203, 193, 0.09) -4.67%,
    rgba(117, 187, 253, 0.09) 44.63%,
    rgba(188, 188, 188, 0.09) 103.07%
  );
  box-shadow: -2px 8px 31.1px 0px rgba(103, 151, 194, 0.22);
}

@keyframes ellipse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
    opacity: 0.8;
    box-shadow: -2px 8px 31.1px 0px rgba(103, 151, 194, 0.18);
  }
  100% {
    transform: scale(1);
  }
}

/* ---- Перетекающие кружки ---- */

.filter {
  position: absolute;
  visibility: hidden;
}

.dots {
  filter: url(#gooeyness);
  -webkit-filter: url(#gooeyness);
  padding: 22px;
}

.dot {
  will-change: transform;
  background: var(--white-F6F6F6);
  border-radius: 50%;
  display: inline-block;
  margin-right: 28px;
  width: 28px;
  height: 28px;
}

.dot:first-child {
  animation: FirstDot 4s ease-in-out infinite;
}

.dot:nth-child(2) {
  animation: SecondDot 4s ease-in-out infinite;
}

.dot:nth-child(3) {
  animation: ThirdDot 4s ease-in-out infinite;
}

.dot:nth-child(4) {
  animation: FourthDot 4s ease-in-out infinite;
}

.dot:nth-child(5) {
  animation: FifthDot 4s ease-in-out infinite;
}

@keyframes FirstDot {
  0% {
    transform: scale() translateX(0);
  }
  25% {
    transform: scale(1.8) translateX(0);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  83% {
    transform: scale(1) translateX(220px);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes SecondDot {
  0% {
    transform: translateX(0px);
  }
  27% {
    transform: translateX(-40px);
  }
  50% {
    transform: translateX(0px);
  }
  81% {
    transform: translateX(180px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes ThirdDot {
  0% {
    transform: translateX(0px);
  }
  29% {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(0px);
  }
  79% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FourthDot {
  0% {
    transform: translateX(0px);
  }
  31% {
    transform: translateX(-160px);
  }
  50% {
    transform: translateX(0px);
  }
  77% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes FifthDot {
  0% {
    transform: scale(1) translateX(0);
  }
  33% {
    transform: scale(1) translateX(-220px);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  75% {
    transform: scale(1.8) translateX(0);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes animate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

/* Стили для мобильной версии начинаются тут */
@media screen and (max-width: 767px) {
  .conentLoaderLogo {
    display: flex;
  }
  .dots {
    filter: url(#gooeyness);
    padding: 0;
    transform: scale(0.5);
  }

  .ellipseFirst {
    width: 560px;
    height: 560px;
  }
  .ellipseSecond {
    width: 460px;
    height: 460px;
  }
  .ellipseThird {
    width: 360px;
    height: 360px;
  }
  .ellipseFour {
    width: 260px;
    height: 260px;
  }
  .itemLoaderLogo {
    padding: 0 56px 0 56px;
  }
  .contentLoaderSubtitle {
    font-size: 2.7vw;
    line-height: 2.7rem;
    padding-bottom: 20px;
  }
  .percent {
    font-size: 4.4vw;
  }
}

/* Создаем стили для контейнера лоадера */
.loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Создаем стили для самого лоадера */
.loader {
  border: 12px solid rgba(255, 203, 193, 0.18);
  border-top-color: #ff9988;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s ease-in-out infinite;
}

/* Создаем анимацию вращения */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
