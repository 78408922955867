@import '../../../App.css';
@import '../../../_variables.scss';

.VacanciesMap {
  $s: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding-left: rem(64);
  overflow: hidden;

  &__text {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 20%;
    margin: rem(64) 0 rem(128) auto;

    h2 {
      font-family: var(--font-unbounded);
      font-size: rem(40);
      line-height: rem(48);
      margin-bottom: rem(45);
    }

    p {
      font-family: var(--font-raleway);
      margin-bottom: rem(32);
      font-size: rem(24);
      line-height: rem(32);
    }
  }

  &__map {
    flex-shrink: 0;
    position: relative;
    width: 1574px;
    height: 1400px;
    background-image: url('../icons/vacancies-cities.png');
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__circle {
    position: absolute;
    border-radius: 100%;
    background-color: var(--blue-light-BADDFE);
    transform-origin: center center;
    animation: ellipse 4s ease infinite;

    &_xs {
      width: 17px;
      height: 17px;
      opacity: 1;
      z-index: 50;
      animation: none;
      background-color: var(--blue-75BBFD);
    }

    &_s {
      width: 60px;
      height: 60px;
      z-index: 40;
      opacity: 0.3;
      animation-delay: 700ms;
    }

    &_m {
      width: 97px;
      height: 97px;
      z-index: 30;
      opacity: 0.16;
      animation-delay: 900ms;
    }
  }

  &__city {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      position: absolute;
      transform: translateY(-75px);
      z-index: 60;
      font-size: 19px;
      white-space: nowrap;
    }

    &_msk {
      top: 249px;
      right: 756px;

      #{$s}__box {
        width: 661px;
        height: 661px;
      }

      span {
        font-size: 25px;
        transform: translateY(-87px);
      }
    }

    &_vzh {
      top: 656px;
      right: 1127px;
    }

    &_spb {
      top: 341px;
      right: 1005px;
    }

    &_obg {
      top: 905px;
      right: 852px;
    }

    &_chbk {
      top: 882px;
      right: 669px;
    }
  }

  &__box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center center;
    width: 97px;
    height: 97px;
  }

  .VacanciesMap__city_active {
    .VacanciesMap__circle {
      animation: pulse 3s infinite;
    }
  }


  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(3.5);
    }

    100% {
      transform: scale(1);
    }
  }



  @media all and (max-width: 2000px) {
    &__text {
      width: 30%;
      margin: 0 0 rem(160) auto;

      h2 {

        font-size: rem(32);
        line-height: rem(40);
        margin-bottom: rem(24);
      }

      p {
        margin-bottom: rem(32);
        font-size: rem(18);
        line-height: rem(26);
      }
    }

    &__map {
      width: 960px;
      height: 880px;
      background-position: bottom -30px right -196px;
      background-size: 113%;
    }

    &__circle {
      &_xs {
        width: 12px;
        height: 12px;
      }

      &_s {
        width: 42px;
        height: 42px;
      }

      &_m {
        width: 70px;
        height: 70px;
      }
    }

    &__city {
      span {
        font-size: 14px;
        transform: translateY(-47px);
      }

      &_msk {
        top: 80px;
        right: 356px;

        span {
          font-size: 18px;
          transform: translateY(-58px);
        }

        #{$s}__box {
          width: 470px;
          height: 470px;
        }
      }

      &_vzh {
        top: 364px;
        right: 619px;
      }

      &_spb {
        top: 139px;
        right: 533px;
      }

      &_obg {
        top: 536px;
        right: 428px;
      }

      &_chbk {
        top: 520px;
        right: 303px;
      }
    }

    &__box {
      width: 70px;
      height: 70px;
    }
  }

  // Адаптация под tablet
  @media all and (max-width: $L) {
    &__text {
      width: 38%;
      margin: rem(64) 0 rem(64) auto;

      h2 {
        font-size: rem(24);
        line-height: rem(32);
      }

      p {
        font-size: rem(18);
        line-height: rem(26);
      }
    }

    &__map {
      width: rem(599);
      height: rem(572);
      background-position: bottom -82px right -148px;
      background-size: 118%;
    }

    &__box {
      width: 45px;
      height: 45px;
    }

    &__circle {
      &_xs {
        width: 8px;
        height: 8px;
      }

      &_s {
        width: 27px;
        height: 27px;
      }

      &_m {
        width: 45px;
        height: 45px;
      }
    }

    &__city {
      span {
        font-size: 9px;
        transform: translateY(-34px);
      }

      &_msk {
        top: 110px;
        right: 208px;

        span {
          font-size: 12px;
          transform: translateY(-38px);
        }

        #{$s}__box {
          width: 306px;
          height: 306px;
        }
      }

      &_vzh {
        top: 296px;
        right: 379px;
      }

      &_spb {
        top: 156px;
        right: 324px;
      }

      &_obg {
        top: 409px;
        right: 255px;
      }

      &_chbk {
        top: 398px;
        right: 173px;
      }
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    flex-direction: column;
    height: auto;
    padding: 0;

    &__text {
      position: static;
      width: 100%;
      padding: rem(120) rem(16) 0 rem(16);
      margin-bottom: 0;

      h2 {
        font-size: rem(24);
        line-height: rem(32);
      }
    }

    &__map {
      width: 375px;
      height: 507px;
      background-position: bottom -10px right -140px;
      background-size: cover;
      margin-left: auto;
    }

    &__city {
      span {
        transform: translateY(-24px);
      }

      &_msk {
        top: 59px;
        right: 109px;

        span {
          font-size: 12px;
        }
      }

      &_vzh {
        top: 234px;
        right: 272px;
      }

      &_spb {
        top: 121px;
        right: 228px;
      }

      &_obg {
        top: 324px;
        right: 172px;
      }

      &_chbk {
        top: 315px;
        right: 107px;
      }
    }
  }
}