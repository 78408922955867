@import '../../../../App.css';
@import '../../../../_variables.scss';

.main_vacancies_block {
  width: 100%;
  height: 100%;
  min-height: 81vh;
}

.wrapper_vacancies {
  border-top: 1px solid var(--light-light-gray-EBECEE);
  border-bottom: 1px solid var(--light-light-gray-EBECEE);
  background: linear-gradient(
    261deg,
    rgba(255, 203, 193, 0.09) -4.67%,
    rgba(117, 187, 253, 0.09) 44.63%,
    rgba(188, 188, 188, 0.09) 103.07%
  );
  backdrop-filter: blur(7px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 615px;
  margin-top: 49px;
}

.rocket_model {
  top: -60px;
  position: absolute;
}

.vacancies_header_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--black-1D2E43);
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-300);
  top: 50%;
  left: 7rem;
  padding-right: 1rem;
}

.vacancies_header1 {
  font-size: 20px;
  line-height: 28px;
}

.vacancies_header2 {
  font-size: 48px;
  line-height: 64px;
}

/* стили для кнопок фильтрации */
.menu_button_group_vacancy_wrapper_main {
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
}

.menu_button_group_vacancy_wrapper {
  display: flex;
  width: 100%;
}

.menu_button_group_vacancy {
  display: flex;
  flex-wrap: wrap;
  padding-left: 3rem;
  padding-right: 5rem;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  width: 100%;
  cursor: pointer;
}

.menu_button_group_vacancy div,
.menu__button__allVacancy {
  height: 40px;
}

/* .button_wrapper {
display: inline-block;

} */

.menu__button__allVacancy {
  display: flex;
  width: fit-content;
  padding: 8px 24px;
  align-items: center;
  justify-content: center;
  border-radius: 2.125rem;
  border: 1px solid var(--black-1D2E43);
  background-color: transparent;
  white-space: nowrap;
  color: var(--black-1D2E43);
  cursor: pointer;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.menu__button__allVacancy:hover {
  background-color: var(--dark-gray-566272);
  color: var(--white-F6F6F6);
  transition: background-color 0.4s ease, color 0.4s ease;
  border: 1px solid var(--black-1D2E43);
}

.menu__button__allVacancy.active {
  background-color: var(--black-1D2E43);
  color: var(--white-F6F6F6);
  border: none;
}

/* .menu__button__allProject.active:hover {
  background-color: var(--black-1D2E43);
} */

/* .menu_button_items_vacancy {
display: flex;
font-family: var(--font-raleway);
} */

.menu_button_items_vacancyText {
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 1.25rem;
}

.menu_button_items_vacancyCount {
  display: block;
  margin-left: 3px;
  transform: translateY(-20%);
  font-size: 0.6rem;
  font-weight: var(--fw-400);
  line-height: 1.75rem;
}

.menu__button__choose__vacancyFilter {
  display: inline-flex;
  padding: 0.7rem 1rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 3rem;
  margin-left: 0.5rem;
  width: auto;
  gap: 0.5rem;
  border-radius: 2.125rem;
  background-color: var(--light-light-gray-EBECEE);
  border: none;
  transition: background-color 0.4s ease, color 0.4s ease;
  height: 40px;
}

.menu__button__choose__vacancyFilter:hover {
  background: var(--light-gray-C0C5CB);
  /* color: var(--white-F6F6F6); */
  transition: background-color 0.4s ease, background-color 0.4s ease;
}

.icon__svg_button__choose__vacancyFilter {
  width: 1rem;
  height: 1rem;
}

.button_choose_vacancyFilter {
  border: none;
  font-family: var(--font-raleway);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 1.25rem;
  color: var(--black-1D2E43);
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

/* стили для модального окна */

.modal_wrapper_vacancy {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45%;
  height: 100%;
  margin-top: 0px;
  border: none !important;
  outline: none;
  border-radius: 0;
  background-color: rgba(246, 246, 246, 0.92);
  box-shadow: -2px 4px 22px 0px rgba(29, 46, 67, 0.46);
  backdrop-filter: blur(8.5px);
  animation: fadeInOut 0.5s ease-out forwards;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal_vacancy_close {
  padding-top: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  cursor: pointer;
}

.fixed_modal_vacancy {
  position: sticky;
  top: 0rem;
  right: 0rem;
}

.filterModalContent {
  display: flex;
  flex-direction: column;
  padding: 4.5rem 6rem 0rem 6.5rem;
}

.filterName_button_modal_wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  max-width: 33rem;
  width: 100%;
}

.filterName {
  color: var(--black-1D2E43);
  font-family: var(--font-unbounded);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--fw-300);
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 8px;
}

.filterName_title {
  color: var(--black-1D2E43);
  font-family: var(--font-raleway);
  font-size: 18px;
  font-weight: var(--fw-400);
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 31px;
}

.filterName_button_modal {
  padding-top: 16px;
}

.filterName_button_modal button {
  margin-right: 8px;
  margin-bottom: 8px;
}

.filterModalButton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 8px 24px;
  box-sizing: border-box;
  border: 1px solid #1d2e43;
  border-radius: 2.12rem;
  background-color: var(--white-F6F6F6);
  color: var(--black-1D2E43);
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

@media (min-width: 1025px) {
  .filterModalButton:hover {
    background-color: var(--light-gray-C0C5CB);
    border: 1px solid var(--light-gray-C0C5CB);
  }
}

.filterModalButton.activeFilter {
  background-color: var(--black-1D2E43);
  color: var(--white-F6F6F6);
}

.applyButtonWrapper {
  display: flex;
  padding-top: 148px;
  padding-bottom: 5rem;
}

.applyButton {
  width: 24rem;
  padding: 16px 32px;
  background-color: var(--blue-75BBFD);
  color: var(--white-F6F6F6);
  border: none;
  border-radius: 4.12rem;
  cursor: pointer;
}

.applyButton:hover {
  background-color: var(--dark-blue-389FFF);
}

/* _______________________________________________________ */

.dopFilterName {
  display: flex;
  align-items: flex-start;
  padding-left: 1.69rem;
  padding-top: 24px;
  color: var(--gray-959DA6);
  font-family: var(--font-raleway);
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 20px;
}

.activeFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: 8px;
  padding-top: 8px;
  padding-left: 1.69rem;
  justify-content: start;
}

.dopFilterWrapper {
  padding-left: 1.69rem;
}

.activeFilterTag {
  display: flex;
  padding: 8px 24px;
  background-color: var(--black-1D2E43);
  border-radius: 2rem;
  border: 1px solid var(--black-1D2E43);
  align-items: center;
  font-size: 0.8rem;
  cursor: default;
}

.dopFilterButtonName {
  color: var(--white-F6F6F6);
  font-family: var(--font-raleway);
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 20px;
}

.xSpan {
  position: static;
  width: 14.14px;
  height: 14.14px;
  padding-left: 11px;
  color: var(--white-F6F6F6);
}

.clearAllFiltersButton {
  display: flex;
  padding: 8px 24px;
  background-color: var(--white-F6F6F6);
  border-radius: 2rem;
  border: 1px solid var(--black-1D2E43);
  align-items: center;
  font-size: 0.8rem;
}

.dopFilterButtonNameClear {
  color: var(--black-1D2E43);
  font-family: var(--font-raleway);
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 20px;
}

.xSpanClear {
  display: flex;
  position: static;
  width: 14.14px;
  height: 14.14px;
  padding-left: 11px;
  color: var(--black-1D2E43);
  align-items: center;
  justify-content: center;
  text-align: center;
}

.temporaryText {
  max-width: 47rem;
  color: var(--black-1D2E43);
  font-family: var(--font-unbounded);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: var(--fw-300);
  line-height: 3rem;
  padding-left: 7rem;
  padding-top: 11.25rem;
}

.temporaryText_intership {
  max-width: 65rem;
}

.textUnderForm {
  /* max-width: 47rem; */
  color: var(--black-1D2E43);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: var(--font-raleway);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: var(--fw-400);
  line-height: 1.625rem;
  padding-left: 7rem;
  padding-top: 1.5rem;
}

.vacancyFormWrapper {
  padding: 0 0rem 5rem 7rem;
}

.menu_button_group_vacancy_wrapper_main_swiper {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .wrapper_vacancies {
    height: 325px;
  }

  .vacancies_header_wrapper {
    max-width: 400px;
  }

  .vacancies_text_for_email {
    min-width: 70vw;
    width: 100%;
  }

  .vacancies_list {
    min-width: 50vw;
  }

  .temporaryText {
    padding-left: 4rem;
  }

  .textUnderForm {
    max-width: 40rem;
    padding-left: 4rem;
  }

  .filterModalContent {
    padding: 0rem 5.5rem 0rem 5.5rem;
  }

  .filterName_button_modal_wrapper {
    padding-bottom: 0px;
    max-width: 30rem;
  }

  .filterName_title {
    padding-top: 1.5rem;
  }

  .filterName_button_modal {
    padding-top: 1rem;
  }

  .applyButtonWrapper {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }

  .vacancyFormWrapper {
    padding: 0rem 0rem 6rem 4rem;
  }

  .modal_wrapper_vacancy {
    height: 100vh;
  }

  .filterModalContent {
    height: 100%;
  }

  .applyButtonWrapper {
    margin-top: auto;
  }

  .filterName {
    padding-bottom: 24px;
  }

  .filterName_button_modal button {
    margin-bottom: 16px;
  }
}

/* стили для планшетной версии  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wrapper_vacancies {
    height: 344px;
  }

  .vacancies_header_wrapper {
    top: 113px;
    left: 3.43rem;
    padding-right: 1rem;
    max-width: 420px;
    background-color: transparent;
  }

  .vacancies_header1 {
    font-size: 24px;
    line-height: 32px;
  }

  .vacancies_header2 {
    font-size: 48px;
    line-height: 64px;
    padding-top: 4px;
  }

  .vacancy_section_block {
    display: inline-flex;
    flex-direction: column;
    padding: 5rem 4vw;
  }

  .vacancies_advertisingBlock {
    padding-right: 0rem;
    max-width: 40rem;
  }

  .vacancies_section {
    width: 100%;
  }

  .vacancies_list_container {
    display: inline-flex;
    padding: 2rem 1.88rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    border: 1px solid var(--blue-75BBFD);
    width: 100%;
  }

  .vacancies_list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
  }

  .vacancy_name:hover {
    color: var(--black-1D2E43);
    cursor: pointer;
  }

  .vacancies_text_for_email {
    width: fit-content;
  }

  .span_mail_vacancies {
    cursor: pointer;
  }

  .temporaryText {
    max-width: 44rem;
    padding-left: 4vw;
    padding-top: 2.5rem;
  }

  .textUnderForm {
    max-width: 44rem;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .vacancyFormWrapper {
    padding: 0rem 4vw 4.2rem 4vw;
  }

  .menu_button_group_vacancy_wrapper,
  .menu__button__choose__vacancyFilter {
    display: none;
  }

  .menu_button_group_vacancy_wrapper_main_swiper {
    display: flex;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;
  }

  .menu_button_group_vacancy_swiper_mobile {
    padding-left: 17vw;  
  }

  .menu__button__choose__vacancyFilter_mobile {
    display: flex;
    width: fit-content;
    max-height: calc(100% - 8px);
    padding: 8px 24px;
    align-items: center;
    justify-content: center;
    border-radius: 2.125rem;
    border: 1px solid var(--black-1D2E43);
    background-color: transparent;
    white-space: nowrap;
    color: var(--black-1D2E43);
    transition: background-color 0.4s ease, color 0.4s ease;
    margin-right: 8px;
    cursor: none;
  }

  .menu__button__choose__vacancyFilter_mobile_dop {
    display: flex;
    width: fit-content;
    max-height: calc(100% - 8px);
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 2.125rem;
    border: 1px solid var(--black-1D2E43);
    background-color: var(--black-1D2E43);
    white-space: nowrap;
    color: var(--white-F6F6F6);
    transition: background-color 0.4s ease, color 0.4s ease;
    margin-right: 8px;
    cursor: none;
  }

  .menu_button_items_vacancyTex_clear {
    display: flex;
    width: fit-content;
    max-height: calc(100% - 6px);
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    background-color: var(--light-light-gray-EBECEE);
    border-radius: 50%;
  }

  .menu__button__choose__vacancyFilter_mobile.active {
    background-color: var(--black-1D2E43);
    color: var(--white-F6F6F6);
  }

  .icon__svg_button__choose__vacancyFilter_mobile {
    width: 1rem;
    height: 1rem;
  }

  .filterWhiterBackground {
    background-color: var(--black-1D2E43);
  }

  .temporaryText {
    max-width: 100%;
    color: var(--black-1D2E43);
    font-size: 40px;
    line-height: 48px;
    padding: 100px 10.8rem 0px 10.8rem;
  }

  .textUnderForm {
    max-width: 100%;
    font-size: 18px;
    line-height: 26px;
    padding: 24px 10.8rem 0px 10.8rem;
  }

  .vacancyFormWrapper {
    padding: 0 10.8rem 7rem 10.8rem;
  }

  .modal_wrapper_vacancy {
    width: 60%;
  }

  .modal_vacancy_close {
    padding-top: 2.188rem;
    padding-left: 90%;
    cursor: none;
  }

  .filterModalContent {
    display: flex;
    flex-direction: column;
    padding: 24px 2rem 0rem 2rem;
  }

  .filterName_button_modal_wrapper {
    max-width: 30rem;
  }

  .filterName_title {
    padding-top: 32px;
  }

  .applyButtonWrapper {
    display: flex;
    padding-top: 16px;
  }
  .filterModalButton {
    cursor: none;
  }

  .applyButton {
    cursor: none;
  }

  .applyButton:hover {
    background-color: var(--blue-75BBFD);
  }
}

/* стили мобильной версии  */

@media screen and (max-width: 768px) {
  .wrapper_vacancies {
    height: 376px;
  }

  .rocket_image,
  .rocket_model {
    display: none;
  }

  .vacancies_header_wrapper {
    top: 45%;
  }

  .vacancies_header1 {
    font-family: var(--font-raleway);
    font-weight: var(--fw-400);
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
    max-width: 90%;
  }

  .vacancies_header2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    padding-top: 8px;
  }

  .vacancy_section_block {
    padding: 2.5rem 1rem;
    padding-bottom: 0rem;
  }

  .vacancies_advertisingBlock {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
    /* max-width: 21rem; */
  }

  .vacancies_advertisingBlock_text1 {
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: var(--fw-300);
    line-height: 1.75rem;
  }

  .vacancies_advertisingBlock_text2 {
    color: var(--black-1D2E43, #1d2e43);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 1.625rem;
    padding-top: 1rem;
  }

  .vacancies_section {
    font-display: flex;
    padding: 2.5rem 0rem 0rem 0rem;
  }

  .vacancies_list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  .vacancy_name:hover {
    color: var(--black-1D2E43);
    cursor: pointer;
  }

  .vacancies_text_for_email {
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: var(--fw-300);
    line-height: 2rem;
    padding: 2.5rem 0rem 0rem 0rem;
    width: 100%;
  }

  .span_mail_vacancies {
    display: flex;
    color: var(--blue-75BBFD);
    font-family: var(--font-unbounded);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: var(--fw-300);
    line-height: 2rem;
    text-decoration-line: underline;
    padding-left: 0rem;
    cursor: pointer;
  }

  .menu_button_group_vacancy_wrapper,
  .menu__button__choose__vacancyFilter {
    display: none;
  }

  .menu_button_group_vacancy_wrapper_main_swiper {
    display: flex;
    -webkit-overflow-scrolling: touch;
    padding-top: 24px;
  }

  .menu_button_group_vacancy_swiper_mobile {
    width: 100%;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  /* .menu_button_group_vacancy_swiper_mobile {
    display: flex;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    width: 100%;
    overflow-x: hidden;
    padding-left: 1rem;
    align-items: center;
  } */

  .menu__button__choose__vacancyFilter_mobile {
    display: flex;
    width: auto;
    max-height: calc(100% - 8px);
    padding: 8px 24px;
    align-items: center;
    justify-content: center;
    border-radius: 2.125rem;
    border: 1px solid var(--black-1D2E43);
    background-color: transparent;
    white-space: nowrap;
    color: var(--black-1D2E43);
    cursor: pointer;
    transition: background-color 0.4s ease, color 0.4s ease;
    margin-right: 8px;
    cursor: none;
  }

  .menu__button__choose__vacancyFilter_mobile_dop {
    display: flex;
    width: fit-content;
    max-height: calc(100% - 8px);
    padding: 8px 24px;
    align-items: center;
    justify-content: center;
    border-radius: 2.125rem;
    border: 1px solid var(--black-1D2E43);
    background-color: var(--black-1D2E43);
    white-space: nowrap;
    color: var(--white-F6F6F6);
    cursor: pointer;
    transition: background-color 0.4s ease, color 0.4s ease;
    margin-right: 8px;
    cursor: none;
  }

  .menu_button_items_vacancyTex_clear {
    display: flex;
    width: fit-content;
    max-height: calc(100% - 6px);
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    background-color: var(--light-light-gray-EBECEE);
    border-radius: 50%;
  }

  .menu__button__choose__vacancyFilter_mobile.active {
    background-color: var(--black-1D2E43);
    color: var(--white-F6F6F6);
  }

  .icon__svg_button__choose__vacancyFilter_mobile {
    width: 1rem;
    height: 1rem;
  }

  .filterWhiterBackground {
    background-color: var(--black-1D2E43);
  }

  .temporaryText {
    max-width: 100%;
    padding: 60px 1rem 0rem 1rem;
    font-family: var(--font-unbounded);
    font-size: 24px;
    font-weight: var(--fw-300);
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }

  .textUnderForm {
    max-width: 100%;
    padding: 16px 1rem 0rem 1rem;
    font-family: var(--font-raleway);
    font-weight: var(--fw-400);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .vacancyFormWrapper {
    padding: 0rem 1rem 8.31rem 1rem;
  }

  .modal_wrapper_vacancy {
    width: 100%;
  }

  .modal_vacancy_close {
    padding-top: 2.188rem;
    padding-left: 90%;
    cursor: none;
  }

  .filterModalContent {
    display: flex;
    flex-direction: column;
    padding: 24px 2rem 0rem 2rem;
  }

  .filterName {
    padding-bottom: 0px;
  }

  .filterName_title {
    padding-top: 24px;
  }
  .filterModalButton {
    cursor: none;
  }
  .applyButtonWrapper {
    display: flex;
    padding-top: 6px;
  }

  .showManyButtonWrapperShifted {
    margin-top: -20px;
  }

  .applyButton {
    cursor: none;
  }

  .applyButton:hover {
    background-color: var(--blue-75BBFD);
  }
}
