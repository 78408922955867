@import '../../../App.css';

$offset: 187;
$duration: 2.5s;
$radius: 0.25rem;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  border-radius: $radius;
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #feb0a4;
  }

  25% {
    stroke: #ffa597;
  }

  50% {
    stroke: #fec6bd;
  }

  75% {
    stroke: #ff9f8f;
  }

  100% {
    stroke: #feb0a4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset*0.25;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.inner-circle {
  transform: none;
}

.div_round {
  position: relative;
  width: 28.75px; // уменьшено ещё в 2 раза
  height: 28.75px; // уменьшено ещё в 2 раза
  border-radius: 50%;
  box-shadow: 0 0 0 5px #e2eefb; // уменьшено ещё в 2 раза
}

.div_svg {
  position: relative;
  display: inline-block;
  height: 40.625px; // уменьшено ещё в 2 раза
  width: 40.625px; // уменьшено ещё в 2 раза
  margin: 0em;
  border-radius: 50%;
  background: none repeat scroll 0 0 rgb(231 235 240);
  overflow: hidden;
}

.div_svg:after {
  content: '';
  position: absolute;
  top: 8.25px; // уменьшено ещё в 2 раза
  left: 8.25px; // уменьшено ещё в 2 раза
  display: block;
  height: 25px; // уменьшено ещё в 2 раза
  width: 25px; // уменьшено ещё в 2 раза
  background: none repeat scroll 0 0 var(--white-F6F6F6);
  border-radius: 50%;
  box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.1); // уменьшено ещё в 2 раза
}
