@import '../App.css';

.information_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.information_h1 {
  display: flex;
  padding-top: 150px;
  padding-left: 20vw;
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 40px;
  line-height: 48px;
  color: var(--black-1D2E43);
}

.information_p {
  padding-top: 40px;
  padding-left: 20vw;
  padding-right: 10vw;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 24px;
  color: var(--black-1D2E43);
}

.information_p:last-of-type {
  padding-bottom: 100px;
}

/* Стили для планшетной версии сайта блока Information начинаются тут */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .information_h1 {
    display: flex;
    padding-top: 6rem;
    padding-left: 1rem;
  }

  .information_p {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .information_p:last-of-type {
    padding-bottom: 4rem;
  }
}

/* Стили для мобильной версии сайта блока Information начинаются тут */
@media screen and (max-width: 767px) {
  .information_h1 {
    display: flex;
    font-family: var(--font-unbounded);
    font-style: normal;
    font-weight: var(--fw-400);
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--black-1D2E43);
    padding-top: 5rem;
    padding-left: 1rem;
  }

  .information_p {
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .information_p:last-of-type {
    padding-bottom: 3rem;
  }
}
