@import '../../App.css';
@import '../../_variables.scss';

.CompanySlider {
  $s: &;
  margin: rem(180) 0;

  &__title {
    padding: 0 rem(30);
    margin-bottom: rem(60);
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-size: rem(40);
    line-height: rem(48);
  }

  * > &__swiper {
    overflow: visible !important;
    padding: 0 rem(30) !important;

    & > * {
      justify-content: space-between;
      gap: 24px;
    }
  }

  &__slide {
    &:first-child {
      #{$s}__picture {
        justify-content: flex-start;
      }
    }

    &:last-child {
      #{$s}__picture {
        justify-content: flex-end;
      }
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      height: rem(80);
      width: auto;
      max-width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  &__text {
    display: none;
    margin-top: rem(24);
    font-size: rem(18);
    line-height: rem(26);
    font-weight: 400;
    color: var(--black-3C3C3C);
  }

  &__pagination {
    display: none;
    text-align: center;
    z-index: 10;
    margin-top: rem(30);
  }

  &__bullet {
    width: rem(15);
    height: rem(5);
    background-color: var(--light-light-gray-EBECEE);
    display: inline-block;
    margin: 0 rem(4);
    transition: 0.5s;
  }

  &__bulletActive {
    width: rem(48);
    background-color: var(--salmon-FF7F6A);
  }

  @media all and (min-width: $XXL) {
    &__slide {
      width: auto !important;
    }
  }

  @media all and (max-width: 2200px) {
    &__picture {
      img {
        height: rem(68);
      }
    }
  }

  @media all and (max-width: $XL) {
    &__picture {
      img {
        height: rem(58);
      }
    }

    * > &__swiper > * {
      gap: 0px;
    }
  }

  // Адаптация под меньший размер desktop
  @media all and (max-width: $XL) {
    &__text {
      font-size: rem(14);
      line-height: rem(20);
    }

    &__picture {
      img {
        height: rem(48);
      }
    }
  }

  @media all and (max-width: $L) {
    margin: rem(120) 0;

    &__pagination {
      display: block;
    }
  }

  @media all and (max-width: $M) {
    &__title {
      padding: 0 5.4vw;
    }

    * > &__swiper {
      padding: 0 5.4vw !important;
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    margin: rem(48) 0 rem(80) 0;

    &__title {
      padding: 0 rem(16);
      margin-bottom: rem(48);
      font-size: rem(24);
      line-height: rem(32);
    }

    * > &__swiper {
      padding: 0 rem(16) !important;
    }

    &__picture {
      img {
        height: rem(48);
      }
    }

    &__slide {
      &:first-child {
        #{$s}__picture {
          justify-content: center;
        }
      }

      &:last-child {
        #{$s}__picture {
          justify-content: center;
        }
      }
    }
  }
}
