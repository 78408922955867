@import '../../App.css';

.explosionContainer {
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px; 
  height: 1px;
  z-index: 9999;
  pointer-events: none;
}

.explosionDot {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--white-F6F6F6); 
  border-radius: 50%;
  animation: explodeDot 1s ease-out forwards;
  transform: translate(0, 0);
  pointer-events: none;
}

@keyframes explodeDot {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(calc(var(--x) * 150px), calc(var(--y) * 150px)) scale(1);
    opacity: 0;
  }
}
