@import '../../App.css';

.containerImageToDescription {
  margin-bottom: 60px;

  &__img {
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 50vw;
    border-radius: 16px;
    object-fit: cover;
  }

  &__text {
    color: var(--gray-959DA6);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 1.25rem;
    padding-top: 0.5rem;
    word-wrap: break-word;
  }
}

// ваыаываы

.blockHeadingTitle {
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;

  &__heading {
    color: var(--black-1D2E43);
    font-family: var(--font-unbounded);
    font-size: 24px;
    font-style: normal;
    font-weight: var(--fw-300);
    line-height: 32px;
    word-wrap: break-word;
    margin-bottom: 40px;
  }

  &__text {
    color: var(--black-1D2E43);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--fw-400);
    line-height: 24px;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

// <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 485px 485px;
  /* Здесь используем fr для автоматического распределения пространства */
  gap: 30px;
  height: 100%;
  align-items: stretch;
  margin-bottom: 60px;

  @media screen and (min-width: 1025px) and (max-width: 1600px) {
    grid-template-rows: 354px 354px;
  }
}

.horizontalImage,
.verticalImage {
  width: 100%;
  height: 100%;
  object-position: center;
  /* Для корректного отображения изображений внутри блока */
  object-fit: cover;
  /* Изображение будет подгоняться так, чтобы заполнить свой блок без искажений */
  display: block;
  /* Убираем стандартные отступы для изображений */
}

.horizontalImage img {
  grid-column: 1 / 2;
  // height: 50vh;
  border-radius: 1rem;
  object-fit: cover;
  /* 50% высоты экрана */
}

.horizontalImage:nth-child(1) {
  grid-row: 1 / 2;
  border-radius: 1rem;
}

.horizontalImage:nth-child(2) {
  grid-row: 2 / 3;
  border-radius: 1rem;
}

.verticalImage {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  // height: 100vh;
  border-radius: 1rem;
  /* 100% высоты экрана */
}

// <<< ====== стили для блока с двумя вертикальными картинками ====== >>>
// .imageBlockTwo {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 30px;
//     // height: 100vh;
//     padding-bottom: 3.75rem;

//     &__gridImage {
//         width: 100%;
//         height: 100%;
//         border-radius: 16px;
//         object-fit: cover;
//     }
// }
.imageBlockTwo {
  display: flex;
  gap: 30px;
  margin-bottom: 3.75rem;
}

.imageBlockTwo__gridImage {
  flex: 1;
  max-width: 49%;
  border-radius: 16px;
  object-fit: cover;
}

.slider_container {
  padding-bottom: 4.75rem;
}

.wrapper_conteiner__item {
  display: flex;
  margin-bottom: 60px;
}

.div_conteiner__blockSix {
  width: 100%;
  max-width: 100%;
}

.block_video {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 40vw;
  border-radius: 16px;
}

.buttonWrapperBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonPlayBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.225rem;
  height: 7.225rem;
  border-radius: 7.225rem;
  background: var(--, linear-gradient(135deg, rgba(187, 222, 255, 0.55) 3.43%, rgba(255, 191, 181, 0.55) 177.6%));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonPause {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.225rem;
  height: 7.225rem;
  border-radius: 7.225rem;
  background: var(--, linear-gradient(135deg, rgba(187, 222, 255, 0.55) 3.43%, rgba(255, 191, 181, 0.55) 177.6%));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonWrapperBlock1 {
  position: absolute;
  bottom: 18%;
  right: 3%;
}

.buttonFullscreen {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.buttonExitFullscreen {
  display: flex;
  width: 4rem;
  height: 4rem;
  bottom: 18%;
}

.buttonFullscreen img,
.buttonExitFullscreen img {
  width: 100%;
  height: 100%;
}

.videoLinkContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 3.75rem;
}

.block_videoLink {
  width: 100%;
  min-height: 40vw;
  border-radius: 16px;
}

/* Стили для desctop версии +1 сайта блока ProjectCard начинаются тут */

@media screen and (min-width: 1025px) and (max-width: 1600px) {
  .blockHeadingTitle {
    padding-right: 23vw;
    padding-left: 23vw;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .text_framed {
    padding-right: 0;
    padding-left: 0;
  }

  .note_block {
    padding-right: 0;
    padding-left: 0;
  }

  .text_block {
    padding-right: 0;
    padding-left: 0;
  }

  .quote_block_constainer {
    padding-right: 0;
    padding-left: 0;
  }

  .buttonWrapperBlock1 {
    position: absolute;
    bottom: 20%;
    right: 3%;
  }
}

/* Стили для планшетной версии сайта блока ProjectCardConstructor, начинаются тут */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .containerImageToDescription {
    margin-bottom: 40px;
  }

  .blockHeadingTitle {
    display: flex;
    flex-direction: column;
    // gap: 1.5rem;
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 40px;
    margin-top: 40px;

    &__heading {
      color: var(--black-1D2E43);
      font-family: var(--font-unbounded);
      font-size: 24px;
      font-style: normal;
      font-weight: var(--fw-300);
      line-height: 32px;
      word-break: break-word;
    }

    &__text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      word-break: break-word;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  // <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    /* Здесь используем fr для автоматического распределения пространства */
    gap: 24px;
    height: 100%;
    align-items: stretch;
    margin-bottom: 40px;
  }

  .horizontalImage,
  .verticalImage {
    width: 100%;
    /* Для корректного отображения изображений внутри блока */
    object-fit: cover;
    /* Изображение будет подгоняться так, чтобы заполнить свой блок без искажений */
    display: block;
    /* Убираем стандартные отступы для изображений */
  }

  .horizontalImage {
    grid-column: 1 / 2;
    max-height: 24rem;
    border-radius: 1rem;
    /* 50% высоты экрана */
  }

  .horizontalImage:nth-child(1) {
    grid-row: 1 / 2;
    min-height: auto;
  }

  .horizontalImage:nth-child(2) {
    grid-row: 2 / 3;
    min-height: auto;
  }

  .verticalImage {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    // height: 100vh;
    border-radius: 1rem;
    max-height: 816px;
    /* 100% высоты экрана */
  }

  .quote_block_constainer {
    position: relative;
    margin-bottom: 40px;
    word-wrap: break-word;
    padding-right: 0;
    padding-left: 0;

    &__text_block {
      display: flex;
      padding: 24px;
      margin-right: 3rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 16px;
      background-color: var(--light-light-gray-EBECEE);
    }

    &__quote_text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;

      /* Body Regular · 16 */
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      /* 150% */
    }

    &__quote_author {
      color: var(--gray-959DA6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 14px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 20px;
    }

    &__author_block {
      position: absolute;
      top: 0px;
      right: -21%;
    }

    &__img {
      border-radius: 50%;
      width: 10rem;
      height: 10rem;
      object-fit: cover;
      object-position: center;
    }

    &__quote {
      color: var(--dark-gray-566272);
      font-family: var(--font-raleway);
      font-size: 12px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 16px;
      padding-top: 10px;
    }

    &__author {
      color: var(--gray-959DA6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 12px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 16px;
    }
  }

  .text_framed {
    padding-right: 0;
    padding-left: 0;
  }

  .note_block {
    padding-right: 0;
    padding-left: 0;
  }

  .text_block {
    padding-right: 0;
    padding-left: 0;
  }

  .imageBlockTwo {
    margin-bottom: 40px;
  }

  .slider_container {
    padding-bottom: 56px;
  }
  .div_conteiner__blockSix {
    width: 100%;
    max-width: 100%;
  }

  .videoLinkContainer {
    padding-left: 0;
    margin-bottom: 40px;
  }
}

.buttonWrapperBlock1 {
  position: absolute;
  bottom: 20%;
  right: 3%;
}

.buttonFullscreen {
  width: 2rem;
  height: 2rem;
}

.buttonExitFullscreen {
  width: 2rem;
  height: 2rem;
}

/* Стили для мобильной версии сайта блока ProjectCardConstructor, начинаются тут */
@media screen and (max-width: 767px) {
  .containerImageToDescription {
    padding-top: 0.5rem;
    margin-bottom: 2.13rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
  }

  .wrapper_conteiner__item {
    margin-bottom: 24px;
  }

  .blockHeadingTitle {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    padding-left: 0;
    padding-right: 0;

    &__heading {
      color: var(--black-1D2E43);
      font-family: var(--font-unbounded);
      font-size: 24px;
      font-style: normal;
      font-weight: var(--fw-300);
      line-height: 32px;
      word-wrap: break-word;
      white-space: normal;
    }

    &__text {
      color: var(--black-1D2E43);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: var(--fw-400);
      line-height: 24px;
      word-wrap: break-word;
      white-space: normal;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  // <<<< =========== стили для блока у которого 2 горизонтальные картинки и 1 вертикальная картинка imageBlockThree =========== >>>>

  .gridContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: 100%;
    height: 100%;
    // min-height: 60vh;
    // overflow: hidden;
    margin-bottom: 24px;
    // padding-left: 1rem;
    // padding-right: 1rem;
  }

  .horizontalImage,
  .verticalImage {
    width: 100%;
    object-fit: cover;
    display: block;
    height: auto;
    border-radius: 1rem;
    object-position: center;
  }

  .horizontalImage {
    grid-column: 1 / 2;
    min-height: 20vh;
    height: 100%;
    border-radius: 1rem;
    /* 50% высоты экрана */
  }

  .horizontalImage:nth-child(1) {
    grid-row: 1 / 2;
  }

  .horizontalImage:nth-child(2) {
    grid-row: 2 / 3;
  }

  .verticalImage {
    grid-column: 1 / 2;
    min-height: 20vh;
    height: 100%;
    border-radius: 1rem;
  }

  // <<< ====== стили для блока с двумя вертикальными картинками ====== >>>
  .imageBlockTwo {
    // display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // padding-top: 10rem;

    &__gridImage {
      width: 100%;
      min-width: 100%;
      min-height: 20vh;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 16px;
    }
  }

  // <<< ====== стили для блока заметкой  ====== >>>

  .quote_block_constainer {
    &__author_block {
      display: none;
    }

    &__text_block {
      margin-right: 0rem;
      word-wrap: break-word;
    }
  }

  // <<< ====== стили для слайдера с картинками =) ====== >>>

  .slider_container {
    padding-bottom: 56px;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  // .custom_slider {
  //   padding-right: 10px;
  // }

  .div_conteiner__blockSix {
    width: 100%;
    max-width: 100%;
  }
  .videoLinkContainer {
    padding-left: 0;
    margin-bottom: 24px;
  }

  .buttonWrapperBlock1 {
    position: absolute;
    bottom: 25%;
    right: 3%;
  }

  .buttonFullscreen {
    width: 2rem;
    height: 2rem;
  }

  .buttonExitFullscreen {
    width: 2rem;
    height: 2rem;
  }
}
