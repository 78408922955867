@import '../../App.css';


.skeleton_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 4rem;
  padding-left: 0vw;
  padding-right: 1.87vw;

&__item_stuff { 
  width: 100%;
  min-width: 22vw;
  height: 42vh;
  display: flex;
  flex-direction: column;
  animation: skeleton-loading 1s infinite ease-in-out;
  background-color: #e0e0e0;
  border-radius: 1rem;
}

}
@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  
  50% {
    background-color: #f0f0f0;
  }
  
  100% {
    background-color: #e0e0e0;
  }
}


// Планшетная версия
.skeleton_slider_container_desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 42vh;
   min-width: 22vw;
  border-radius: 1rem;
  padding-left: 3.5rem;
  margin-bottom: 6rem;

  &__item_stuff {
    display: flex;
     height: 42vh;
    flex-direction: column;
    animation: skeleton-loading 1s infinite ease-in-out;
    background-color: #e0e0e0;
    border-radius: 1rem;
    margin-right: 1.88rem;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}

// Мобильная версия
.skeleton_slider_container_mobile {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 42vh;
  gap: 1rem;
  background-color: #e0e0e0;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  animation: skeleton-loading 1s infinite ease-in-out;
  margin-bottom: 5rem;

  &__item_stuff {
    width: 100%;
    min-width: 22vw;
    height: 42vh;
    display: flex;
    flex-direction: column;
    background-color: #e0e0e0;
    border-radius: 1rem;
  }
}




