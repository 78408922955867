@import '../../../App.css';

.div_conteiner {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  // margin-left: -20px;
  // margin-right: -20px;

  &__item {
    width: 141px;
    height: 116px;
    background-color: var(--salmon-light-FFBFB5);
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--salmon-FF7F6A);
    }
  }

  &__blockOne {
    padding: 22px 22px 21px 33px;
    width: 141px;
    height: 116px;
  }

  &__blockTwo {
    padding: 35px 32px 41px 33px;
  }

  &__blockThree {
    padding: 22px 21px 22px 22px;
  }

  &__blockFour {
    padding: 22px 21px 22px 22px;
  }

  &__blockFive {
    padding: 32px 10px;
  }

  &__blockSix {
    padding: 32px;
  }

  &__blockVideoFrame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

.videoText {
  font-size: 1rem;
  color: var(--white-F6F6F6);
  white-space: nowrap;
  margin-top: -20px;
}

.constructorBlockOne {
  padding: 22px 22px 21px 33px;
}

.notification {
  position: fixed;
  top: 6%;
  right: -330px;
  /* Ширина вашего уведомления */
  width: 320px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  transition: right 1s;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.showNotification {
  right: 20px;
}

// .constructorBlockTwo {}

// .constructorBlockThree {}

// .constructorBlockFour {}

// .constructorBlockFive {}

// .constructorBlockSix {}

@media screen and (max-width: 1024px) {
  .div_conteiner {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
}
