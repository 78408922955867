$XXL: 1921px;
$XL: 1441px;
$L: 1281px;
$M: 1025px;
$S: 768px;
$XS: 577px;
$XXS: 378px;

@function rem($pixels) {
  @return calc($pixels / 16) + rem;
}
