@import './../_variables.scss';

.FooterInfo {
  $s: &;
  font-family: var(--font-raleway);
  position: relative;
  overflow: hidden;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__socials {
    align-self: flex-end;
    display: flex;
    gap: rem(24);
    margin-bottom: rem(4);

    li a {
      font-size: rem(14);
      font-weight: var(--fw-900);
      line-height: 142%;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      transition: color 0.4s ease;
      position: relative;
      z-index: 3;

      &:hover {
        color: var(--salmon-light-FFBFB5);
        transition: color 0.4s ease;
      }
    }
  }

  &__contacts {
    font-family: var(--font-raleway);
    display: grid;
    row-gap: rem(8);
    column-gap: rem(56);
    grid-template-columns: max-content max-content 1fr;
    grid-template-rows: auto auto;
    margin-bottom: rem(48);
  }

  &__office,
  &__phone {
    font-size: rem(18);
    font-weight: bold;
    line-height: 133%;
  }

  &__phone {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__number,
  &__address {
    font-size: rem(14);

    a {
      transition: color 0.4s ease;
      position: relative;
      z-index: 3;

      &:hover {
        color: var(--salmon-light-FFBFB5);
        transition: color 0.4s ease;
      }
    }
  }

  &__address {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  &__mail {
    justify-self: flex-end;
    align-self: flex-end;
    font-size: rem(32);
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;

    a {
      transition: color 0.4s ease;
      position: relative;
      z-index: 3;

      &:hover {
        color: var(--salmon-light-FFBFB5);
        transition: color 0.4s ease;
      }
    }
  }

  &__footnote {
    display: flex;
    justify-content: flex-end;
    gap: rem(20);
    font-size: rem(14);
    line-height: 142%;
    color: var(--light-gray-C0C5CB);

    span {
      text-transform: uppercase;
    }

    a {
      transition: color 0.4s ease;
      position: relative;
      z-index: 3;

      &:hover {
        color: var(--salmon-light-FFBFB5);
        transition: color 0.4s ease;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    &__office {
      margin-bottom: rem(14);
    }

    &__footnote {
      align-self: flex-start;
    }

    &__socials {
      align-self: flex-start;
      order: 2;
      margin-bottom: rem(24);
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      gap: rem(4);
      margin-bottom: rem(24);
      order: 1;
    }

    &__phone {
      display: none;
      order: 1;
    }

    &__number,
    &__address {
      font-size: rem(14);
      line-height: 142%;
    }

    &__address {
      order: 3;
    }

    &__mail {
      order: 2;
      justify-self: flex-start;
      align-self: flex-start;
      font-size: rem(14);
    }

    &__footnote {
      order: 3;
    }
  }

  @media screen and (max-width: $S) {
    &__office {
      font-size: rem(16);
    }

    &__address {
      font-size: rem(12);
    }
  }
}
