@import '../App.css';

.error_section {
  width: 100%;
  height: 100vh;
  color: var(--black-1D2E43);
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #dee6ed, #bcdbf9, #f3e2e1);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

.error_number {
  font-family: var(--font-unbounded);
  font-weight: var(--fw-300);
  font-size: 20.83vw;
  line-height: 25.83vw;
}

.error_block {
  position: fixed;
  bottom: 17.06vw;
  right: 5.53vw;
}

.error_title {
  font-family: var(--font-unbounded);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 2.43vw;
  line-height: 2.99vw;
  padding-bottom: 1.11vw;
}

.error_text {
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: var(--fw-400);
  font-size: 1rem;
  line-height: 1.67vw;
  padding-bottom: 3.75rem;
}

.error_link {
  color: var(--black-1D2E43);
  border: 1px solid var(--black-1D2E43);
  padding: 1.11vw 2.22vw;
  border-radius: 4.58vw;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: linear-gradient(278.5deg, rgba(220, 238, 255, 0.53) -49.88%, rgba(255, 127, 106, 0.53) 108.02%);
    border: 1px solid rgba(255, 127, 106, 0.53);
  }
}

// стили для мобильной версии 404 компонента

@media screen and (max-width: 700px) {
  .error_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
  }

  .error_block_mobail {
    all: unset;
  }

  .error_number {
    font-family: var(--font-unbounded);
    font-style: normal;
    font-weight: var(--fw-300);
    font-size: 141px;
    line-height: 175px;
  }

  .error_title {
    font-family: var(--font-unbounded);
    font-style: normal;
    font-weight: var(--fw-300);
    font-size: 1.5rem;
    line-height: 1.875rem;
    padding-bottom: 1rem;
  }

  .error_text {
    font-family: var(--font-raleway);
    font-style: normal;
    font-weight: var(--fw-300);
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .error_link {
    padding: 1rem 1.875rem;
    border-radius: 4.125rem;
  }
}
