@import '../../../App.css';
@import '../../../_variables.scss';

.Intro {
  padding-top: rem(48);

  &__intro {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 rem(71) 0 rem(113);
    min-height: rem(1280);
    border-top: 1px solid var(--light-light-gray-EBECEE);
    border-bottom: 1px solid var(--light-light-gray-EBECEE);
    background: linear-gradient(
      261deg,
      rgba(255, 203, 193, 0.09) -4.67%,
      rgba(117, 187, 253, 0.09) 44.63%,
      rgba(188, 188, 188, 0.09) 103.07%
    );
    backdrop-filter: blur(7px);
  }

  &__starsWrapper {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }

  &__animation {
    position: absolute;
    z-index: -10;
    right: rem(71);
    top: rem(128);
    max-height: rem(1005);
    max-width: rem(1007);
    overflow-y: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 60%;
    padding-top: rem(285);
    color: var(--black-1D2E43);
    font-style: normal;
    font-weight: var(--fw-300);
    font-family: var(--font-unbounded);
  }

  &__pretitle {
    display: inline-block;
    margin-bottom: rem(36);
    font-size: rem(32);
    line-height: rem(40);
  }

  &__title {
    font-size: rem(72);
    line-height: rem(80);
  }

  &__button {
    margin-top: rem(48);
  }

  &__video {
    background-color: aliceblue;
    position: relative;
    overflow: hidden;

    video {
      transition: all 1s ease-out;
      width: 0;
      height: 0;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  // Адаптация под меньший размер desktop
  @media all and (max-width: $XXL) {
    &__intro {
      min-height: rem(780);
      padding: 0 rem(44) 0 rem(62);
    }

    &__text {
      width: 100%;
      max-width: 80%;
      padding-top: rem(132);
    }

    &__pretitle {
      font-size: rem(24);
      line-height: rem(28);
      margin-bottom: rem(8);
    }
    &__title {
      font-size: rem(48);
      line-height: rem(64);
    }
    &__animation {
      right: rem(47);
      top: rem(55);
      max-height: rem(732);
      max-width: rem(697);
    }
  }

  // Адаптация под tablet
  @media all and (max-width: $M) {
    &__intro {
      min-height: 325px;
      padding: 0 rem(55);
    }

    &__pretitle {
      font-size: rem(20);
    }

    &__text {
      width: 100%;
      max-width: 100%;
      padding: rem(113) 0 rem(48) 0;
    }

    &__button {
      margin-top: rem(32);
    }

    &__animation {
      right: 0;
      top: 0;
      max-height: 100%;
      max-width: rem(500);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom 67px right 26px;
        filter: blur(5px);
      }
    }
  }

  // Адаптация под mobile
  @media all and (max-width: $S) {
    &__text {
      width: 100%;
      max-width: 100%;
      padding: 0 rem(16);
    }

    &__intro {
      padding: rem(64) 0 rem(64) 0;
    }

    &__pretitle {
      font-size: rem(18);
      line-height: rem(26);
    }

    &__title {
      font-size: rem(32);
      line-height: rem(40);
    }

    &__animation {
      max-height: 100%;
      max-width: 100%;

      img {
        object-position: center center;
      }
    }

    &__video {
      video {
        width: 100%;
        height: 50vh;
      }
    }
  }
}
