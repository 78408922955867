@import '../../../App.css';

.form_container {

  &__block_gray_one {
    position: relative;
    background-color: var(--light-light-gray-EBECEE);
    padding: 2rem 1rem 2rem 1rem;
    border-radius: 6px;
    margin-bottom: 2rem;
  }

  &__close_button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    padding: 0;

    svg {
      display: block;
      width: 11.5px;
      height: 11.5px;
    }
  }

  &__checkbox_block {
    color: var(--light-gray-C0C5CB);
    font-size: 0.75rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }


  &__heading_block {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-left: 1rem;
  }

  &__heading_block_input {
    border: 1px solid var(--blue-75BBFD);
    border-radius: 6px;
    padding: 1rem 3.5rem 1rem 1rem;
    margin-left: -1rem;
    font-family: var(--font-raleway);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
      color: var(--gray-959DA6);
      font-family: var(--font-raleway);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .add_author_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    padding: 1rem;
    border: 1px dashed var(--blue-75BBFD);
    font-size: 16px;
    font-weight: 400;
    color: var(--blue-75BBFD);
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: var(--dark-blue-389FFF);
      border-color: var(--dark-blue-389FFF);
    }

    svg {
      width: 25px;
      height: 24px;
      transition: fill 0.3s ease;
    }

    .add_author_text {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: currentColor;
    }
  }
}


.slider_container {
  &__title {
    color: var(--light-gray-C0C5CB);
    font-size: 0.75rem;
    font-family: var(--font-raleway);
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding-bottom: 0.5rem;
  }

  &__customFileUpload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
    padding: 0.5rem 1rem;
    background-color: var(--blue-75BBFD);
    color: var(--white-F6F6F6);
    border-radius: 20px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, color 0.3s;
    margin-top: 0.5rem;

    &:hover {
      background-color: var(--dark-blue-389FFF);
    }
  }


  &__customFileUpload input[type='file'] {
    position: absolute;
    left: -9999px;
  }
}

.preview_image {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &__block {
    flex-basis: calc(25% - 12px);
    padding-top: 8px;
  }

  &__img {
    width: 5rem;
    height: 3rem;
    border-radius: 0.39rem;
    object-fit: cover;
    object-position: center;

  }

  @keyframes wave {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
    }
  }

  &__loading {
    font-family: var(--font-raleway);
    color: var(--black-1D2E43);
    animation: wave 1s infinite;
  }


}